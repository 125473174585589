// src/components/SignInWithGoogle.js
import React, {useEffect, useState} from 'react';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword,sendPasswordResetEmail,sendEmailVerification,onAuthStateChanged} from 'firebase/auth';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import './SignInWithGoogle.css';
import {auth} from "../firebase-config";


const SignInWithEmail = () => {
    const { auth,setCurrentUser,logOut } = useAuth();
    const navigate = useNavigate();

    const [signupEmail, setSignupEmail] = useState('');
    const [signupPassword, setSignupPassword] = useState('');
    const [signinEmail, setSigninEmail] = useState('');
    const [signinPassword, setSigninPassword] = useState('');
    const [signupConfirmPassword, setSignupConfirmPassword] = useState('');
    const [signupMessage, setSignupMessage] = useState('');
    const [signinMessage, setSigninMessage] = useState('');
    const [allowResetPassword, setAllowResetPassword] = useState(false);
    const[resetPasswordEmail, setResetPasswordEmail] = useState('');
    const [validPassword, setValidPassword] = useState(false);
    const [user, setUser] = useState(null);
    const [signup, setSignup] = useState(false);
    const [resendVerificationMail, setResendVerificationMail] = useState(false);
    const [emailVerified, setEmailVerified] = useState(false);
    const [disableSignInButton, setDisableSignInButton] = useState(false);
    const [disableSignupButton, setDisableSignupButton] = useState(false);
    const [displayVerificationMailWarning, setDisplayVerificationMailWarning] = useState(false);
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setCurrentUser(currentUser);
            setUser(currentUser);
            if (signup) {
                setSignup(false);
                setTimeout(() => {
                     logOut();
                    },5000)
            } else {
                if(currentUser && currentUser.emailVerified) {
                    navigate('/');
                } else {
                    console.log('User not verified:',currentUser);
                    logOut();
                }
            }
        });

        return () => unsubscribe();
    }, [auth]);

    const signUp = async () => {
        setDisableSignupButton(true);
        if (signupPassword !== signupConfirmPassword) {
            setDisableSignupButton(false);
            setSignupMessage('Passwords do not match');
            console.log('Passwords',signupPassword,signupConfirmPassword)
            return;
        }
        await createUserWithEmailAndPassword(auth,signupEmail, signupPassword)
            .then(async (userCredential) => {
                const user = userCredential.user;
                await sendEmailVerification(user)
                    .then(() => {
                        setSignupMessage('Verification email sent to: ' + user.email+' Please verify your email');
                        setTimeout(() => {
                            logOut();
                            //setSigninMessage('Please verify your email')
                            setDisplayVerificationMailWarning(true);
                            setSigninMessage('Verification email sent to: ' + user.email+' Please verify your email');
                            setSignup(false);
                        },1000);
                        setSigninEmail('');
                        setSigninPassword('');
                        setSignupMessage('');
                        setSignupEmail('');
                        setSignupPassword('');
                        setSignupConfirmPassword('');
                        setDisableSignupButton(false);
                        setTimeout(() => {
                            setSigninMessage('');
                            setSignupMessage('');
                            setDisplayVerificationMailWarning(false);
                        },30000)
                    })
                    .catch((error) => {
                        setDisableSignupButton(false);
                        setSignupMessage('Error sending email verification: ' + error.message);
                    });
            })
            .catch((error) => {
                setDisableSignupButton(false);
                setSignupMessage(`Error: ${error.message}`);
            });
    };

    const VerifySignupPassword = () => {
        if (signupPassword !== signupConfirmPassword) {
            setSignupMessage('Passwords do not match');
            setValidPassword(false);
            setDisableSignupButton(false);
            //console.log('Passwords',signupPassword,signupConfirmPassword)
        } else {
            setSignupMessage('');
            setValidPassword(true);
        }
    }

    useEffect(() => {
        VerifySignupPassword();
    }, [signupPassword,signupConfirmPassword]);

    const signIn = async () => {
        setDisableSignInButton(true);
        await signInWithEmailAndPassword(auth,signinEmail, signinPassword)
            .then((userCredential) => {
                const user = auth.currentUser;
                if (user.emailVerified) {
                    console.log('Sign in successful for verified user:', signinEmail);
                    setSigninMessage('Sign in successful');
                    setDisableSignInButton(false);
                    setTimeout(() => {
                        navigate('/');
                        setDisplayVerificationMailWarning(false);
                    }, 5000);
                } else {
                    console.error('Email not verified. Please verify your email.');
                    setSigninMessage('Email not verified. Please verify your email or request for resend verification mail by clicking on "Resend Verification Mail" button.');
                    setResendVerificationMail(true);
                    setDisableSignInButton(false);
                    setDisplayVerificationMailWarning(true);
                }
            })
            .catch((error) => {
                setSigninMessage(`Error: ${error.message}`);
                setDisableSignInButton(false);
            });
    };

    const resetPassword = async (email) => {
        await sendPasswordResetEmail(auth, email);
        setResetPasswordEmail('');
        setAllowResetPassword(false);
        setSigninMessage('Password reset email sent');
        setTimeout(() => {
            navigate('/');
        }, 10000);
    };

    const resendEmailVerification = async () => {
        const user = auth.currentUser;
        await signInWithEmailAndPassword(auth,signinEmail, signinPassword)
            .then( async (userCredential) => {
                const user = auth.currentUser;
                await sendEmailVerification(user)
                    .then(() => {
                        setSigninMessage('Verification email sent to: ' + user.email + ' Please verify your email');
                        setTimeout(() => {
                            logOut();
                            navigate('/');
                        }, 10000);
                    })
                    .catch((error) => {
                        setSigninMessage('Error sending email verification: ' + error.message);
                    });
            }).catch((error) => {
                setSigninMessage(`Error: ${error.message}`);
            });
    };

    useEffect(() => {
        if (!displayVerificationMailWarning) {
            setSignupMessage('');
            setSigninMessage('');
        }
    },[displayVerificationMailWarning]);

    const toggleForm = () => {
        setSignup(!signup);
        if (!displayVerificationMailWarning) {
            setSignupMessage('');
            setSigninMessage('');
        }
        setAllowResetPassword(false);
    };

    const acceptEmailForPasswordReset = () => {
        setAllowResetPassword(true);
    }

    return (
        <div className="flex justify-center items-center bg-white">
            <div className="bg-white p-8 rounded shadow-md w-80">
                {signup && <div>
                    <h2 className="text-2xl mb-4">Sign Up</h2>
                    <input
                        type="email"
                        placeholder="Email"
                        value={signupEmail}
                        onChange={(e) => setSignupEmail(e.target.value)}
                        className="w-full p-2 mb-4 border rounded"
                        required
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={signupPassword}
                        onChange={(e) => {
                            setSignupPassword(e.target.value)
                        }
                        }
                        className="w-full p-2 mb-4 border rounded"
                        required
                    />
                    <input
                        type="password"
                        placeholder="Confirm Password"
                        value={signupConfirmPassword}
                        onChange={(e) => {
                            setSignupConfirmPassword(e.target.value);
                        }
                        }
                        className="w-full p-2 mb-4 border rounded"
                        required
                    />
                    <button
                        onClick={signUp}
                        className={`w-full p-2 bg-green-500 text-white rounded hover:bg-green-600 ${validPassword ? '' : 'cursor-not-allowed'}`}
                        disabled={(!validPassword)&&disableSignupButton}
                    >
                        Sign Up
                    </button>

                    <div className="text-red-500 mt-4">{signupMessage}</div>
                    <p className="mt-4 text-center">
                        Already have an account?{' '}
                        <button onClick={toggleForm} className="text-blue-700 hover:underline font-bold">
                            Sign In
                        </button>
                    </p>
                </div>}
                {!signup && !allowResetPassword && <div>
                    <h2 className="text-2xl  mb-4">Sign In</h2>
                    <input
                        type="email"
                        placeholder="Email"
                        value={signinEmail}
                        onChange={(e) => setSigninEmail(e.target.value)}
                        className="w-full p-2 mb-4 border rounded"
                        required
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={signinPassword}
                        onChange={(e) => setSigninPassword(e.target.value)}
                        className="w-full p-2 mb-4 border rounded"
                        required
                    />
                    <button
                        onClick={signIn}
                        className="w-full p-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                        disabled={disableSignInButton}
                    >
                        Sign In
                    </button>
                    <div className="text-red-500 mt-4">{signinMessage}</div>
                    {
                        resendVerificationMail && (
                            <p className="mt-4 text-center">
                                <button onClick={resendEmailVerification} className="text-blue-500 hover:underline">
                                    Resend Verification Email
                                </button>
                            </p>
                        )
                    }
                    <p className="mt-4 text-center">
                        Don't have an account?{' '}
                        <button onClick={toggleForm} className="text-blue-700 hover:underline font-bold">
                            Sign Up
                        </button>
                    </p>

                    <p className="mt-4 text-center">
                        <button onClick={acceptEmailForPasswordReset} className="text-blue-500 hover:underline">
                            Forgot Password?
                        </button>
                    </p>


                </div>}
                {allowResetPassword && (<div>
                    <h2 className="text-2xl  mb-4">Reset Password</h2>
                    <input
                        type="email"
                        placeholder="Email"
                        value={resetPasswordEmail}
                        onChange={(e) => setResetPasswordEmail(e.target.value)}
                        className="w-full p-2 mb-4 border rounded"
                        required
                    />
                    <button
                        onClick={() => resetPassword(resetPasswordEmail)}
                        className="w-full p-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                    >
                        Reset Password
                    </button></div>)}

            </div>
        </div>
    );
};

export default SignInWithEmail;
