import React, { useState, useEffect } from 'react';
//import api from '../services/api';
import { useAuth } from '../contexts/AuthContext';

const Profile = () => {
    const { currentUser} = useAuth();
    const [name, setName] = useState(''); // Add this line
    const [email, setEmail] = useState(''); // Add this line
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [note, setNote] = useState('');
    const [skills, setSkills] = useState(''); // Add this line
    const [availability, setAvailability] = useState(''); // Add this line
    const [message, setMessage] = useState('');

    useEffect(() => {
        if (currentUser) {
            setName(currentUser.name || ''); // Add this line
            setEmail(currentUser.email || ''); // Add this line
            setAddress(currentUser.address || '');
            setPhone(currentUser.phone || '');
            setNote(currentUser.note || '');
            setSkills(currentUser.skills || ''); // Add this line
            setAvailability(currentUser.availability || ''); // Add this line
        }
    }, [currentUser]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setMessage('Profile updated successfully');
        } catch (error) {
            setMessage('Error updating profile');
            console.error('Error updating profile:', error);
        }
    };

    return (
        <div className="max-w-lg mx-auto p-8 bg-white shadow-lg rounded-lg mb-6">
            <h1 className="text-2xl font-bold mb-4">Profile</h1>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div className="form-group">
                    <label className="block text-sm font-medium text-gray-700">Name:</label>
                    <input
                        type="text"
                        value={name}
                        readOnly
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
                <div className="form-group">
                    <label className="block text-sm font-medium text-gray-700">Email:</label>
                    <input
                        type="text"
                        value={email}
                        readOnly
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
                <div className="form-group">
                    <label className="block text-sm font-medium text-gray-700">Address:</label>
                    <input
                        type="text"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
                <div className="form-group">
                    <label className="block text-sm font-medium text-gray-700">Phone:</label>
                    <input
                        type="text"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
                <button type="submit"
                        className="w-full py-2 px-4 bg-indigo-600 text-white font-semibold rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500">
                    Update Profile
                </button>
            </form>
            {message && <p className="mt-4 text-center text-sm text-gray-600">{message}</p>}
        </div>
    );
};

export default Profile;
