import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {Link, NavLink, useLocation, useNavigate} from 'react-router-dom';
const ProcessDetails =  (props) => {
    //const [result, setResult] = useState(null);
    const [message, setMessage] = useState('Please wait while processing ...');
    useEffect( () => {
        const UpdateDetails = async (props) => {
            console.log('Reference', props.reference)
            console.log('Ticket ID', props.ticketid)
            console.log('Action', props.status)
            console.log('Email', props.email)
            if (props.reference && props.ticketid && props.status && props.email) {
                console.log('Processing ...')
                const res = await axios.post('/process-details', {
                    email: props.email,
                    reference: props.reference,
                    reference2: props.reference2,
                    ticketid: props.ticketid,
                    action: props.status
                });
                ///setResult(res.data);
                console.log('RESULT', res.data)
                setMessage('Process Completed');
            } else {
                setMessage('Invalid request');
            }

        }
        UpdateDetails(props);
    },[]);
    return (
        <>
            <p className="text-grey-300 font-bold">{message}</p>
        </>
    );
}

export default ProcessDetails;