import React, {useContext, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {auth} from './firebase-config';
import Home from './components/Home';
import HowMusicHeals  from "./components/HowMusicHeals";
import TheComposer from './components/TheComposer';
import NavBar from './components/NavBar';

import RegisterForm from './components/RegisterForm';
import Artists from './components/Artists';
import LoginForm from './components/LoginForm';
import ProtectedRoute from './components/ProtectedRoute';
import Profile from "./components/Profile";
import { useLocation } from 'react-router-dom';
import Footer from "./components/Footer";
import UsersList from "./components/UsersList";
import SignInWithGoogle from "./components/SignInWithGoogle";
import ConcertDetails from "./components/ConcertDetails";
import ReserveYourSeat from "./components/ReserveYourSeatOrig";
import ApiCall from "./components/ApiCall";
import PaymentSuccessful from "./components/PaymentSuccessful";
import Bookings from "./components/Bookings";
import GetTicketDetails from "./components/GetTicketDetails";
import AuthHandler from "./components/AuthHandler";
import {AuthProvider} from "./contexts/AuthContext";
import PaymentFailed from "./components/ProcessFailed";
import PaymentCancelled from "./components/ProcessCancelled";
import GetBookings from "./components/GetBookings";
import SignWithEmailPasswordAndVerification from "./components/SignWithEmailPasswordAndVerification";
import HdycFamily from "./components/HdycFamily";
import GetHdycFamilyDetails from "./components/GetHdycFamilyDetails";
import VipTickets from "./components/VipTickets";
import FriendsAndFamily from "./components/FriendsAndFamily";
import BadWeatherNotificatiion from "./components/BadWeatherNotificatiion";
import SendRefundLink from "./components/SendRefundLink";
function App() {

    return (
        <Router basename="/">
            <AuthProvider>
                <AuthHandler />
            <div className="bg-white w-max-full">
                <header className="bg-white">
                    <h1 className="text-3xl text-center font-bold my-4 text-red-600">MUSIC FOR MEDITATION CONCERT</h1>
                </header>
                <NavBar  />
                <main className="bg-white w-max-full">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/the-composer" element={<TheComposer />} />
                        <Route path="/how-music-heals" element={<HowMusicHeals/>}/>
                        <Route path="/artists" element={<Artists/>}/>
                        <Route path="/concert-details" element={<ConcertDetails/>}/>
                        <Route path="/reserve-your-seat" element={<ReserveYourSeat/>}/>
                        <Route path="/register" element={<RegisterForm />} />
                        <Route path="/login" element={<SignInWithGoogle/>} />
                        <Route path="/admin/users" element={<UsersList />} />
                        <Route path="/payment-successful" element={<PaymentSuccessful/>}/>
                        <Route path="/payment-failed" element={<PaymentFailed/>}/>
                        <Route path="/payment-cancelled" element={<PaymentCancelled/>}/>
                        <Route path="/bookings" element={<Bookings/>}/>
                        <Route path="/get-details" element={<GetTicketDetails/>}/>
                        <Route path="/get-bookings-report" element={<GetBookings/>}/>
                        <Route path="/hdyc-family" element={<HdycFamily/>}/>
                        <Route path="/v-i-p-tickets" element={<VipTickets/>}/>
                        <Route path="/friends-and-family" element={<FriendsAndFamily/>}/>
                        <Route path="/send-refund-issued-email" element={<BadWeatherNotificatiion/>}/>
                        <Route path="/send-refund-notification" element={<SendRefundLink/>}/>
                        {/*<Route path="/sign-with-verification" element={<SignWithEmailPasswordAndVerification/>}/>*/}
                        <Route path="/get-hf-details" element={<GetHdycFamilyDetails/>}/>
                        <Route
                            path="/profile"
                            element={
                                <ProtectedRoute>
                                    <Profile />
                                </ProtectedRoute>
                            }
                        />
                    </Routes>
                    {/*<ApiCall/>*/}
                    <Footer />
                </main>
            </div>
            </AuthProvider>
        </Router>
    );
}

export default App;
