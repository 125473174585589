import React, { useState, useEffect } from 'react';
import {useLocation} from 'react-router-dom';
import axios from 'axios';

const GetTicketDetails = () => {
    const [ticketDetails, setTicketDetails] = useState([]);
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const ref2 = params.get('ref');
        const ref2id = params.get('ref2');
        axios.post('/get-booking-details', { reference:ref2, reference2:ref2id})
            .then((response) => {
                setTicketDetails(response.data);

            })
            .catch((error) => {
                console.error('Error fetching ticket details:', error);
            });
    }, []);

    return (
        <div className="mb-20">
            <div className="flex items-center justify-center bg-white ">
                <div className="bg-white p-8 rounded shadow-md w-192">
            <h2 className="text-4xl font-bold text-center text-blue-700 mt-6  mb-6 bg-white">Please Find Your Ticket Details</h2>
                    <div>
                        <div className="text-red-600">
                            <div className="get-details-container">
                                <div className="get-details-content">
                                    <h2>Cancellation Notice: Healing Music Concert with His Holiness Dr. Sri Ganapathy
                                        Sachchidananda Swamiji on July 13th 2024</h2>
                                    <p>We regret to inform you that due to unforeseen circumstances, the Healing Music
                                        Concert with His Holiness Dr. Sri Ganapathy Sachchidananda Swamiji, scheduled
                                        for July 13th 2024, has been cancelled.</p>
                                    <p>We understand the disappointment this may cause and sincerely apologize for any
                                        inconvenience. We will send you a refund link in a separate email in 2 or 3
                                        days.</p>
                                    <p>If you have any questions or need further assistance, please do not hesitate to
                                        contact us at <a href="mailto:info@hdyc.org">info@hdyc.org</a> or Phone/SMS-
                                        1-866-702-4392</p>
                                    <p>Thank you for your continued support and understanding.</p>
                                    <p className="get-details-footer">Warm regards,<br/>HDYC Concert Team</p>
                                </div>
                            </div>
                        </div>
                        {ticketDetails && ticketDetails.map((ticket) => (
                            <div key={ticket.id}>
                                <p><span className="min-w-32 text-amber-600 text-2xl font-bold">Booking ID:</span> <span
                                    className="text-grey-800 text-2xl font-bold">{ticket.id}</span></p>
                                <p><span className="min-w-32 text-amber-600 text-2xl font-bold">Name:</span> <span
                                    className="text-grey-800 text-2xl font-bold">{ticket.firstname} {ticket.lastname}</span>
                                </p>
                                <p><span className="min-w-32 text-amber-600 text-2xl font-bold">Email:</span> <span
                                    className="text-grey-800 text-2xl font-bold">{ticket.email}</span>
                                </p>
                                <p><span className="min-w-32 text-amber-600 text-2xl font-bold">Seating:</span> <span
                                    className="text-grey-800 text-2xl font-bold">{ticket.seating}</span>
                                </p>
                                <p><span className="min-w-32 text-amber-600 text-2xl font-bold">Amount:</span> <span
                                    className="text-grey-800 text-2xl font-bold">{ticket.amount / 100}</span>
                                </p>
                                <p><span className="min-w-32 text-amber-600 text-2xl font-bold">Currency:</span> <span
                                    className="text-grey-800 text-2xl font-bold">{ticket.currency}</span>
                                </p>
                                <p><span
                                    className="min-w-32 text-amber-600 text-2xl font-bold">Number of Tickets:</span>
                                    <span className="text-grey-800 text-2xl font-bold">{ticket.notickets}</span>
                                </p>
                                <p><span className="min-w-32 text-amber-600 text-2xl font-bold">Status:</span> <span
                                    className="text-grey-800 text-2xl font-bold">{ticket.status}</span>
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GetTicketDetails;