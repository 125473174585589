import React, { useState, useEffect } from 'react';
import { getUsers } from '../services/userService';
import {NavLink} from "react-router-dom";
import axios from "axios";
import QRCode from 'qrcode.react';

const Bookings = () => {
    const [bookings, setBookings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBookings = async () => {
            try {

                const response = await axios.get('/ticket-bookings',{});
                //console.log(response); // Log the response to check the data format
                //setBookings(response.data); // Ensure the data is correctly set
            } catch (error) {
                setError('Error fetching users list');
                console.error('Error fetching users list:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchBookings();
    }, []);

    return (
        <div className="mb-20">
            <h1 className="text-3xl font-bold text-amber-700 text-center">Ticket Booking Details</h1>
            <br clear="all"/>
            {error && <p>{error}</p>}
            {!loading && bookings.length === 0 && <p>No bookings available</p>}
            <div>
                {!loading && bookings.length > 0 && (
                    {/*<div className="grid grid-cols-3 gap-4">
                {bookings.map(ticket => (
                    <div key={ticket.id} className="bg-white p-4 shadow-md rounded-lg">
                        <h2 className="text-2xl font-bold text-red-600">{ticket.firstname} {ticket.lastname}</h2>
                        <p><span className="text-amber-600 font-bold">Ticket ID </span><span
                            className="font-bold text-green-600 text-xl">{ticket.id}</span></p>
                        <p><span className="text-amber-600 font-bold">Category </span>{ticket.seating}</p>
                        <p><span className="text-amber-600 font-bold">Email </span>{ticket.email}</p>
                        <p><span className="text-amber-600 font-bold">No. of tickets </span>{ticket.notickets}</p>
                        <p><span className="text-amber-600 font-bold">Amount </span>{ticket.amount / 100}</p>
                        <p><span className="text-amber-600 font-bold">Currency </span>{ticket.currency}</p>
                        <p><span className="text-amber-600 font-bold">Status </span>{ticket.status?ticket.status:"Pending/Not yet resolved"}</p>
                        <QRCode id="qrCodeCanvas" value={`${window.location.origin}/get-ticket/${ticket.id}`}/>
                    </div>
                ))}
                    </div>*/}
                )}
            </div>

        </div>
    );
};

export default Bookings;
