import React, { useState, useEffect } from 'react';
import {useLocation} from 'react-router-dom';
import axios from 'axios';

const GetHdycFamilyDetails = () => {

    const [ticketDetails, setTicketDetails] = useState([]);
    const location = useLocation();
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const ref2 = params.get('ref');
        const ref2id = params.get('ref2');
        let catg = params.get('c');

        if (!catg) {
            catg = 'h';
        }
        axios.post('/get-booking-details-2', { reference:ref2, reference2:ref2id, catg:catg})
            .then((response) => {
                setTicketDetails(response.data);

            })
            .catch((error) => {
                console.error('Error fetching ticket details:', error);
            });
    }, []);

    return (
        <div className="mb-20">
            <div className="flex items-center justify-center bg-white ">
                <div className="bg-white p-8 rounded shadow-md w-192">
                    <h2 className="text-4xl font-bold text-center text-blue-700 mt-6  mb-6 bg-white">Please Find Your
                        Ticket Details</h2>
                    <div>
                        {ticketDetails && ticketDetails.map((ticket) => (
                            <div key={ticket.id}>
                                <p><span className="min-w-32 text-amber-600 text-2xl font-bold">Booking ID:</span> <span
                                    className="text-grey-800 text-2xl font-bold">{ticket.id}</span></p>
                                <p><span className="min-w-32 text-amber-600 text-2xl font-bold">Name:</span> <span
                                    className="text-grey-800 text-2xl font-bold">{ticket.firstname} {ticket.lastname}</span>
                                </p>
                                <p><span className="min-w-32 text-amber-600 text-2xl font-bold">Email:</span> <span
                                    className="text-grey-800 text-2xl font-bold">{ticket.email}</span>
                                </p>
                                <p><span className="min-w-32 text-amber-600 text-2xl font-bold">Seating:</span> <span
                                    className="text-grey-800 text-2xl font-bold">{ticket.seating}</span>
                                </p>
                                <p><span className="min-w-32 text-amber-600 text-2xl font-bold">Amount:</span> <span
                                    className="text-grey-800 text-2xl font-bold">{ticket.amount / 100}</span>
                                </p>
                                <p><span className="min-w-32 text-amber-600 text-2xl font-bold">Currency:</span> <span
                                    className="text-grey-800 text-2xl font-bold">{ticket.currency}</span>
                                </p>
                                <p><span
                                    className="min-w-32 text-amber-600 text-2xl font-bold">Number of Tickets:</span>
                                    <span className="text-grey-800 text-2xl font-bold">{ticket.notickets}</span>
                                </p>
                                <p><span className="min-w-32 text-amber-600 text-2xl font-bold">Status:</span> <span
                                    className="text-grey-800 text-2xl font-bold">{ticket.status}</span>
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GetHdycFamilyDetails;