import React, {useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';
import SriSwamiji from "../assets/img/sri-swamiji.jpg";
import MusicTeam from "../assets/img/img-swamiji-musicteam.a3d9576.jpg";
import SGSBirds from "../assets/img/img-swamiji-wbird.3da8db2.jpg";
import SGSServices from "../assets/img/img-swamiji-charity.5b26576.jpg";
const TheComposer = () => {
    const location = useLocation();
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');
        if (token) {
            console.log('Token updated ...')
            localStorage.setItem('token', token);
            // Remove token from URL
            window.history.replaceState({}, document.title, "/");
        }
    }, [location]);
    return (
        <div className="font-poppins max-w-full -mt-1 bg-white shadow-lg rounded-lg mb-6">
            <img src={SriSwamiji} alt="first slide" className="image-mobile"
                 />
            <h3 className="text-4xl font-bold text-center p-3 text-amber-600">Dr. Sri Ganapathy Sachchidananda Swamiji</h3>
            <div className="container body-alignment">
                <div className='row'>
                    <div >
                        <div className='text-center font-bold text-red-600 text-4xl'>
                            <h4 className="text-2xl">His Holiness Sri Sri Sri</h4>
                            <h3>Ganapathy Sachchidananda Swamiji</h3>
                        </div>
                        <div>
                        <p className="text-xl">His Holiness Parama Pujya Sri Ganapathy Sachchidananda Swamiji is the Founder Pontiﬀ of
                            Avadhoota Datta
                            Peetham
                            at
                            Sri Ganapathy Sachchidananda Ashrama in Mysore, India. Sri Swamiji is worldwide known and
                            famous for His
                            "Concerts
                            for Healing and Meditation". He is a pioneer in the field of music therapy and the author of
                            the compendium
                            “Raga
                            Ragini Nada Yoga” which is an interdisciplinary work on musicology, Yoga, bio-psychic
                            mechanisms and the
                            healing
                            abilities of nature.
                        </p>

                        <p className="text-xl">
                            Based on the ancient Indian Raga Ragini tradition Sri Swamiji developed a musical system in
                            which specific
                            Ragas
                            (sequences of notes) are connected with specific body regions, elements and others. The
                            musicians play
                            according
                            to
                            the rules of the Indian notation system and they play with classical Indian instruments like
                            the violin, flute,
                            ghatam, mrdangam and morsing. Sri Swamiji plays a specially prepared synthesizer which He
                            compares with the
                            Veena,
                            an old Indian string instrument.
                        </p>

                        <p className="text-xl">Concert attendees report about the refreshing, soothing and relaxing effect of this music.
                            Experiences being
                            gained
                            while listening to this music may be the basis to more inner growth, to more inner peace and
                            balance.
                            Since over 30 years Sri Swamiji and His musicians are giving concerts all over the world. In
                            1987 they were in
                            the
                            West for the first time – in the Netherlands – and since then they played in very
                            prestigious concert halls
                            like
                            the Royal Albert Hall in London, the Lincoln Center in New York, the Esplanade in Singapore,
                            Sydney Opera House
                            and
                            the Tonhalle in Zurich. The two biggest concerts until now with an audience counting more
                            than 100,000 people
                            took
                            place in the Lal Bahadur stadium in Hyderabad, India, in 1996 and 2006.</p>


                        <p className="text-xl">One of those concerts took place in the Sydney Opera House on April 6th in 2015. There were
                            over 1800 active
                            participants and this event was awarded with the Guinness World Record for being the biggest
                            music therapy
                            session
                            worldwide. The concerts have a meditative character and serve the relaxation and betterment
                            of stress and
                            strains.
                            Sri Swamiji himself recommends to sit in a comfortable position during the concert and to
                            let oneself in for
                            the
                            music. He considers to be medicine. When the mind is calmed, the music has best chances to
                            take full effect.
                        </p>

                        <p className="text-xl">
                            Sri Swamiji’s oeuvre counts over 200 CDs and DVDs with instrumental music, Bhajans or
                            Chants. Those are
                            available
                            at all well-known providers.
                        </p>
                        </div>
                    </div>
                </div>
                <div className="Swamiji-title">
                    <span> </span>
                    <div className="text-2xl font-bold text-center text-amber-600">
                        <h4>Sri Swamiji's Spiritual, Cultural & Social Welfare Activities</h4>
                        <hr/>
                    </div>
                </div>
                <div className="row artists">
                    <div className="col-md-4 session">
                        <h4>Music & Bhajans</h4>
                        <br/>
                        <br/>
                        <img src={MusicTeam}/>
                        <br/>
                        <br/>
                        <p>Sri Swamiji is a great composer of Bhajans or religious hymns and songs. Sri Swamiji has
                            written more than
                            2000 songs in Sanskrit, Kannada, Telugu, Marathi, Gujarati, Hindi and English in praise of
                            various deities.
                            Endowed with a gifted voice, Sri Swamiji’s bhajan sessions enthrall a musical layman and a
                            Carnatic scholar
                            alike. His Music for Healing and Meditation concerts are world renowned having taken place
                            in historic venues
                            such as Lincoln Center (New York, USA), Synder Opera House (Australia), and Royal Albert
                            Hall (London, UK) to
                            name a few. The divine music at these concerts has impacted the lives of many devotees and
                            music enthusiasts.
                            Visit the Puttugam Library at KSHT to enjoy some of these spiritual treasures.</p>

                        <br/>
                    </div>
                    <div className="col-md-4 session">
                        <h4>SGS Birds & Shuka Vana</h4>
                        <br/>
                        <br/>
                        <img src={SGSBirds} alt="SGS Birds & Shuka Vana"/>
                        <br/>
                        <br/>
                        <p>With His compassionate heart, Sri Swamiji established Shuka Vana (Parrot Park), a
                            rehabilitation center for
                            birds in the serene premises of Sri Ganapathy Sachchidananda Ashrama, Mysuru in 2012. The
                            striking 50 meters
                            high free-flight, one-acre aviary has around 2,100 colorful residents from 468 different
                            species which resulted
                            in Shuka Vana being awarded a Guinness World Record in 2017. Shuka Vana was invited to
                            partner on the project
                            “Year of the Bird 2018” by Audubon, Bird Life International, The Cornell Lab of Ornithology,
                            National
                            Geographic and 190 other reputed organizations on work to protect birds and their habitat,
                            as part of this
                            year’s project – Year of the bird.</p>

                        <br/>
                    </div>
                    <div className="col-md-4 session">
                        <h4>SGS Charitable Allopathic & Ayurvedic Hospital</h4>
                        <br/>
                        <img src={SGSServices}/>
                        <br/>
                        <br/>
                        <p>The Allopathic and Ayurvedic hospital in Datta Peetham caters to the medical needs of the
                            poor and those in
                            need. The medical services available at the hospital include Allopathic out patient
                            services, Ayurvedic
                            out-patient and in-patient treatment facilities (ancient Indian system of medicine),
                            dentistry, physiotherapy,
                            cataract operation program, diabetes detection programs, regular medical check-up camps and
                            a free dispensary/
                            pharmacy. With its own Ayurvedic medicine manufacturing unit, the facility prepares
                            medicines using traditional
                            methods and males them available via the Ashram’s dispensary.</p>
                        <br/>
                    </div>
                </div>
            </div>
            <br/>
        </div>
    );
};

export default TheComposer;
