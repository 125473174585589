import react, { useState, useEffect } from 'react';
import axios from 'axios';

import { useAuth } from '../contexts/AuthContext';
import { format } from 'date-fns';
const GetBookings = () => {
    const { currentUser } = useAuth();
    const [bookings, setBookings] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [generalSeating, setGeneralSeating] = useState(0);
    const [vipSeating, setVipSeating] = useState(0);
    const [preferredSeating, setPreferredSeating] = useState(0);
    const [premiumSeating, setPremiumSeating] = useState(0);
    const [hdycFamilySeating, setHdycFamilySeating] = useState(0);
    const [vipTicketsSeating,setVipTicketSeating] = useState(0);
    const [friendsAndFamilySeating, setFriendsAndFamilySeating] = useState(0);
    const [generalSeatingPrice, setGeneralSeatingPrice] = useState(25);
    const [vipSeatingPrice, setVipSeatingPrice] = useState(2500);
    const [preferredSeatingPrice, setPreferredSeatingPrice] = useState(500);
    const [premiumSeatingPrice, setPremiumSeatingPrice] = useState(1000);
    const [hdycFamilySeatingPrice, setHdycFamilySeatingPrice] = useState(0);
    const [totalGeneralSeating, setTotalGeneralSeating] = useState(0);
    const [totalVipSeating, setTotalVipSeating] = useState(0);
    const [totalPreferredSeating, setTotalPreferredSeating] = useState(0);
    const [totalPremiumSeating, setTotalPremiumSeating] = useState(0);
    const [totalHdycFamilySeating,setTotalHdycFamilySeating] = useState(0);
    const [totalVipTicketSeating,setTotalVipTicketSeating] = useState(0);
    const [totalFriendsAndFamilySeating,setTotalFriendsAndFamilySeating] = useState(0);
    const [totalSeatsForPremium, setTotalSeatsForPremium] = useState(0)
    const [totalSeatsForPreferred, setTotalSeatsForPreferred] = useState(0)
    const [totalSeatsForVip, setTotalSeatsForVip] = useState(0)
    const [totalSeatsForHdycFamily, setTotalSeatsForHdycFamily] = useState(0)
    const [totalSeatsForVipTicket, setTotalSeatsForVipTicket] = useState(0)
    const [totalSeatsForFriendsAndFamily, setTotalSeatsForFriendsAndFamily] = useState(0)
    const [finalTotalTickets, setFinalTotalTickets] = useState(0)


    useEffect(async () => {
        await axios.post('/get-bookings',{
            email:currentUser.email
        })
        .then((response) => {
            setBookings(response.data);
        })
            .catch((error) => {
                setBookings([])
                console.log(error);
            });
    }, []);

    useEffect(() => {
        const computePricing = async () => {
            let total = 0;
            await bookings.forEach((booking) => {
                if (booking.amount > 0) {
                    total += booking.seating == 'General Seating' ? booking.amount * booking.notickets / 100 : booking.amount / 100;
                }
                setFinalTotalTickets(prevState => prevState + parseInt(booking.notickets))
                if (booking.seating === 'General Seating') {
                    setGeneralSeating(prevState => prevState + parseInt(booking.notickets));
                } else if (booking.seating === 'VIP Seating') {
                    setVipSeating(prevState => prevState + parseInt('1'));
                    setTotalSeatsForVip(prevState => prevState + parseInt(booking.notickets));
                } else if (booking.seating === 'Preferred Seating') {
                    setPreferredSeating(prevState => prevState + parseInt('1'));
                    setTotalSeatsForPreferred(prevState => prevState + parseInt(booking.notickets))
                } else if (booking.seating === 'Premium Seating') {
                    setPremiumSeating(prevState => prevState + parseInt('1'));
                    setTotalSeatsForPremium(prevState => prevState + parseInt(booking.notickets))
                } else if (booking.seating === 'HDYC Family') {
                    setHdycFamilySeating(prevState => prevState + parseInt(booking.notickets));
                    setTotalSeatsForHdycFamily(prevState => prevState + parseInt(booking.notickets))
                } else if (booking.seating === 'VIP Tickets') {
                    setVipTicketSeating(prevState => prevState + parseInt(booking.notickets));
                    setTotalSeatsForVipTicket(prevState => prevState + parseInt(booking.notickets))
                } else if (booking.seating === 'Friends and Family') {
                    setFriendsAndFamilySeating(prevState => prevState + parseInt(booking.notickets));
                    setTotalSeatsForFriendsAndFamily(prevState => prevState + parseInt(booking.notickets))
                }
            });
            setTotalAmount(total);
        }
        computePricing();
    }, [bookings]);

    useEffect(() => {
        setTotalGeneralSeating(generalSeatingPrice * generalSeating);
        setTotalVipSeating(vipSeatingPrice * vipSeating);
        setTotalPreferredSeating(preferredSeatingPrice * preferredSeating);
        setTotalPremiumSeating(premiumSeatingPrice * premiumSeating);
    }, [generalSeating,vipSeating, preferredSeating, premiumSeating]);

    const downloadReport = async () => {
        const response = await fetch('/export-bookings',{
            method: 'POST',
            body: JSON.stringify({email:currentUser.email}),
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            console.error('Error generating report:', response.statusText);
            return;
        }
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'concert_bookings.xlsx';
        document.body.appendChild(a);
        a.click();
        a.remove();
    };

    const isMobile = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        return (/android|iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream);
    };

    return (
        <div className="mt-3 mb-20">
            <h1 className="text-center font-bold text-4xl text-amber-600">Concert Bookings</h1>
            {bookings.length === 0 && <p className="text-center">Not Available</p>}
            {bookings.length>0 && <div className="p-3 mt-3 mb-20">
                {!isMobile()  && (
                    <>
                        <div className="max-w-full float-right mr-20 p-3 mt-3 mb-3">
                            <button onClick={downloadReport}
                                    className="bg-blue-600 text-white p-2 rounded-3 shadow-md shadow-lg">Download Report
                            </button>
                        </div>
                        <br clear="all"/>
                        <div className="text-left font-bold text-amber-600 ">Total Amount: ${totalAmount}</div>
                        <div className="text-left font-bold text-amber-600 ">General
                            Seating: {generalSeating} ({`$ ${totalGeneralSeating}`})
                        </div>
                        <div className="text-left font-bold text-amber-600 ">VIP
                            Seating: {vipSeating} ({totalSeatsForVip}) ({`$ ${totalVipSeating}`})
                        </div>
                        <div className="text-left font-bold text-amber-600 ">Preferred
                            Seating: {preferredSeating} ({totalSeatsForPreferred}) ({`$ ${totalPreferredSeating}`})
                        </div>
                        <div className="text-left font-bold text-amber-600 ">Premium
                            Seating: {premiumSeating} ({totalSeatsForPremium}) ({`$ ${totalPremiumSeating}`})
                        </div>
                        <div className="text-left font-bold text-amber-600 ">HDYC Family
                            Seating: {hdycFamilySeating} ({totalSeatsForHdycFamily}) ({`$ ${totalHdycFamilySeating}`})
                        </div>
                        <div className="text-left font-bold text-amber-600 ">VIP Tickets: {vipTicketsSeating} ({totalSeatsForVipTicket}) ({`$ ${totalVipTicketSeating}`})
                        </div>
                        <div className="text-left font-bold text-amber-600 ">Friends And Family
                            Seating: {friendsAndFamilySeating} ({totalSeatsForFriendsAndFamily})
                            ({`$ ${totalFriendsAndFamilySeating}`})
                        </div>
                        <div className="text-2xl text-left font-bold text-green-600 ">
                            Total Seating: {finalTotalTickets}
                        </div>
                        <div className="grid grid-flow-row-dense grid-cols-9 bg-amber-300">
                            <div className="font-bold text-blue-600 p-3">Full Name</div>
                            <div className="font-bold text-blue-600 p-3">Email</div>
                            <div className="font-bold text-blue-600 p-3 text-right">Phone Number</div>
                            <div className="font-bold text-blue-600 p-3">Booking ID</div>
                            <div className="font-bold text-blue-600 p-3 text-right">No. of Tickets</div>
                            <div className="font-bold text-blue-600 p-3 text-right">Amount</div>
                            <div className="font-bold text-blue-600 p-3">Seating</div>
                            <div className="font-bold text-blue-600 p-3">Date and Time</div>
                            <div className="font-bold text-blue-600 p-3">Status</div>
                        </div>
                        {bookings.map((booking, index) => {
                            return <div key={index}
                                        className={`grid grid-flow-row-dense grid-cols-9 ${index % 2 === 0 ? 'bg-gray-200' : 'bg-white'}`}>
                                <div className="text-gray-700 p-3">{booking.firstname} {booking.lastname}</div>
                                <div className="text-gray-700 p-3">{booking.email}</div>
                                <div className="text-gray-700 p-3 text-right">{booking.phoneno}</div>
                                <div className="text-gray-700 p-3">{booking.userbookingid}</div>
                                <div className="text-gray-700 p-3 text-right mr-5">{booking.notickets}</div>
                                <div
                                    className="text-gray-700 p-3 text-right mr-5">{booking.seating == 'General Seating' && booking.amount * booking.notickets / 100}{booking.seating != 'General Seating' && booking.amount / 100}</div>
                                <div className="text-gray-700 p-3">{booking.seating}</div>
                                <div
                                    className="text-gray-700 p-3">{format(new Date(booking.created_at._seconds * 1000), 'MM/dd/yyyy HH:mm:ss')}</div>
                                <div className="text-gray-700 p-3">{booking.status}</div>
                            </div>
                        })}
                    </>)}


                {isMobile() && (
                    <>
                        <div className="text-left font-bold text-amber-600 ">Total Amount:
                            ${totalAmount}</div>
                        <div className="text-left font-bold text-amber-600 ">General
                            Seating: {generalSeating} ({`$ ${totalGeneralSeating}`})
                        </div>
                        <div className="text-left font-bold text-amber-600 ">VIP
                            Seating: {vipSeating} ({totalSeatsForVip}) ({`$ ${totalVipSeating}`})
                        </div>
                        <div className="text-left font-bold text-amber-600 ">Preferred
                            Seating: {preferredSeating} ({totalSeatsForPreferred}) ({`$ ${totalPreferredSeating}`})
                        </div>
                        <div className="text-left font-bold text-amber-600 ">Premium
                            Seating: {premiumSeating} ({totalSeatsForPremium}) ({`$ ${totalPremiumSeating}`})
                        </div>
                        <div className="text-left font-bold text-amber-600 ">HDYC Family
                            Seating: {hdycFamilySeating} ({totalSeatsForHdycFamily}) ({`$ ${totalHdycFamilySeating}`})
                        </div>
                        <div className="text-left font-bold text-amber-600 ">VIP
                            Tickets: {vipTicketsSeating} ({totalSeatsForVipTicket}) ({`$ ${totalVipTicketSeating}`})
                        </div>
                        <div className="text-left font-bold text-amber-600 ">Friends And Family
                            Seating: {friendsAndFamilySeating} ({totalSeatsForFriendsAndFamily})
                            ({`$ ${totalFriendsAndFamilySeating}`})
                        </div>
                        <div className="text-2xl text-left font-bold text-green-600 ">
                            Total Seating: {finalTotalTickets}
                        </div>
                        {bookings.map((booking, index) => {
                            return <div key={index}
                                        className={`mb-3 p-3 rounded-3 shadow-md shadow-lg ${index % 2 === 0 ? 'bg-gray-200' : 'bg-amber=200'}`}>
                                <div className="text-gray-700 p-1"><strong className="min-w-48 font-bold text-blue-600">Full
                                    Name:</strong>{booking.firstname} {booking.lastname}</div>
                                <div className="text-gray-700 p-1"><strong
                                    className="min-w-48 font-bold text-blue-600">Email:</strong>{booking.email}
                                </div>
                                <div className="text-gray-700 p-1"><strong className="min-w-48 font-bold text-blue-600">Phone
                                    No.:</strong>{booking.phoneno}
                                </div>
                                <div className="text-gray-700 p-1"><strong className="min-w-48 font-bold text-blue-600">No.
                                    of Tickets:</strong>{booking.notickets}
                                </div>
                                <div className="text-gray-700 p-1"><strong
                                    className="min-w-48 font-bold text-blue-600">Amount:</strong>{booking.seating == 'General Seating' && booking.amount * booking.notickets / 100}{booking.seating != 'General Seating' && booking.amount / 100}
                                </div>
                                <div className="text-gray-700 p-1"><strong
                                    className="min-w-48 font-bold text-blue-600">Seating:</strong>{booking.seating}
                                </div>
                                <div className="text-gray-700 p-1"><strong className="min-w-48 font-bold text-blue-600">Payment
                                    Status:</strong>{booking.status}
                                </div>
                                <div className="text-gray-700 p-1"><strong className="min-w-48 font-bold text-blue-600">Booking
                                    ID:</strong>{booking.userbookingid}
                                </div>
                                <div className="text-gray-700 p-1"><strong className="min-w-48 font-bold text-blue-600">Date
                                    and
                                    Time:</strong>{format(new Date(booking.created_at._seconds * 1000), 'MM/dd/yyyy HH:mm:ss')}
                                </div>
                            </div>
                        })}
                    </>)}
            </div>}

        </div>
    );

}

export default GetBookings;