import React from 'react';

const Footer = () => {
    return (
        <footer style={footerStyle}>
            <div className="font-bold "><div className="float-left">&nbsp;</div><div className="text-center">Contact Us: 1-866-702-4392 | info@hdyc.org | <a href="https://concert.hdyc.org" className="text-white no-underline">https://concert.hdyc.org</a></div> {/*<div className="float-right">&copy; {new Date().getFullYear()} houstonhealingconcert.org. All rights reserved.</div>*/}</div>
            {/*<p>&copy; {new Date().getFullYear()} houstonhealingconcert.org. All rights reserved.</p>*/}
        </footer>
    );
};

const footerStyle = {
    position: 'fixed',
    left: '0',
    bottom: '0',
    width: '100%',
    backgroundColor: '#333',
    color: 'white',
    textAlign: 'center',
    padding: '1rem 0',
};

export default Footer;
