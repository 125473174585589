import react from 'react';
import { useState } from 'react';
import { useEffect} from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import React from "react";

const SendRefundLink = () => {
    const { currentUser } = useAuth();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [data,setData] = useState([]);
    const [transaction, setTransaction] = useState({});
    const fetchData = async () => {
        setMessage('Please wait while fetching details ...');
        const response = await axios.post('/send-refund-link', {
            email: currentUser.email,
        },{
            timeout: 300000
        })
        if (response.data.success) {
            setMessage('Data loaded successfully.');
            setData(response.data.details);
        } else {
            setMessage('Failed to send refund link.  '+response.data.error);
        }
    }

    const TriggerRefund = async (e) => {
        const response = await axios.post('/trigger-refund', {
            id: e.target.value,
            email: currentUser.email
        },{
            timeout: 300000
        })
        if (response.data.success) {
            setTransaction(response.data.details);
            const details = response.data.details;
            setMessage(`Refund process initiated successfully for ${details.firstname} ${details.lastname}`);
            console.log('Details:',details)
            toast.success(`Refund process initiated successfully for ${details.firstname} ${details.lastname}`)
        } else {
            setMessage('Failed to send refund link.  '+response.data.error);
            toast.error('Failed to send refund link.  '+response.data.error);
        }
    }

    return (
        <>
            <div className="mt-25 mb-6 flex justify-center items-center">
                <div className="mt-25">
        {currentUser && <div>
            <button className="btn btn-primary text-right no-underline text-xl text-gray-600 hover:text-amber-600 px-3 py-2 rounded-md text-lg mt-6 mb-6 font-medium " onClick={fetchData}>Get Details</button>
            {data && data.length > 0 && <div>
                {/*<p className="text-xl font-bold text-blue-600">Refund Link(s) Sent:</p>*/}
                <div>
                    <div className="font-bold text-white grid grid-flow-row-dense grid-cols-5 p-3 bg-amber-600">
                        <div>Name</div>
                        <div>Status</div>
                        <div>Seating</div>
                        <div>No. Of Tickets</div>
                        <div>Action</div>
                    </div>
                    {data.map((item, index) => (
                        <div className={`p-3 grid grid-flow-row-dense grid-cols-5 font-bold text-blue-600 ${index%2==0?'bg-gray-100':'bg-yellow-100'}`}>
                            <div>{item.name}</div><div>{item.status}</div><div>{item.seating}</div><div>{item.notickets}</div>
                            <div><button value={`${item.fkey}`} onClick={TriggerRefund}>Refund</button></div>
                        </div>
                    ))}
                </div>
            </div>
            }
        </div>}
            {!currentUser && <div>
                <p className="text-red-600 font-bold text-3xl">Please log in to send refund notification.</p>
            </div>}
            {loading && <p>Loading...</p>}
            {message && <p className="text-xl font-bold text-blue-600">{message}</p>}
            </div>
            </div>
            <ToastContainer />
        </>
    );
}

export default SendRefundLink;