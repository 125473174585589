import react from 'react';
import axios from 'axios';
import {useEffect, useState} from "react";
import { useAuth } from '../contexts/AuthContext';

const BadWeatherNotification = () => {
    const [badWeather, setBadWeather] = useState(false);
    const { currentUser } = useAuth();

    useEffect(() => {
        axios.post("/send-refund-issued",{
        email:currentUser.email
        }).then((response) => {
            setBadWeather(response.data.message);
        }).catch((error) => {
            console.log(error);
        })
    })
}

export default BadWeatherNotification;