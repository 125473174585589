import React, { useState, useEffect} from 'react';
import TitleImg from "../assets/img/contact.jpg";
import FlyerImg from "../assets/img/hdyc-flyer.jpeg";
import { db } from '../firebase-config';
import { collection, addDoc } from 'firebase/firestore';
const ConcertDetails = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject:'',
        message: ''
    });

    const [errors, setErrors] = useState({});
    const [submitStatus, setSubmitStatus] = useState('');
    const [submitFormError, setSubmitFormError] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validate = () => {
        const errors = {};
        if (!formData.name.trim()) errors.name = 'Name is required';
        if (!formData.email.trim()) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = 'Email is invalid';
        }
        if (!formData.subject.trim()) errors.subject = 'Subject is required';
        if (!formData.message.trim()) errors.message = 'Message is required';
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        setErrors({});
        try {
            const docRef = await addDoc(collection(db, 'concert-2024-contacts'), formData);
            console.log('Document written with ID: ', docRef.id);
            setSubmitStatus('Form submitted successfully!');
            setSubmitFormError('');
            setFormData({ name: '', email: '', subject:'', message: '' });
            setTimeout(function(){
                setSubmitStatus('')
            },10000);
        } catch (e) {
            console.error('Error adding document: ', e);
            setSubmitFormError('Error submitting form.')
            //setSubmitStatus('Error submitting form.');
        }
    };

    return (
        <>
            <div className="mb-20">
        <img src={TitleImg} className="image-mobile" alt="first slide" />
            <h3 className="page-title text-center text-amber-600 font-bold text-4xl p-3">Contact Us</h3>
            <div className="container body-alignment">
                <div className="row">

                    <div className='col-md-6'>
                        <br/><br/>
                            <img src={FlyerImg} alt="first slide" />

                    </div>
                    <div className='col-md-6'>
                        {/*<a  href="/login" className="btn btn-primary tickets-booking float-right">Reserve Your Seat Now !!</a>*/}

                        <br/><br/>
                            <h3 className="contact-title">Contact for more information</h3>
                            <hr/>

                                <form onSubmit={handleSubmit} className="max-w-md mx-auto p-4 shadow-md rounded-lg">
                                    <div className="form-group mb-4">
                                        <label className="block text-sm font-bold mb-2" htmlFor="name">
                                            Name
                                        </label>
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            className="w-full px-3 py-2 border rounded-lg"
                                            required
                                        />
                                        {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
                                    </div>
                                    <div className="form-group mb-4">
                                        <label className="block text-sm font-bold mb-2" htmlFor="email">
                                            Email
                                        </label>
                                        <input type="text" name="email"
                                               id="email"
                                               value={formData.email}
                                               onChange={handleChange}
                                               className="w-full px-3 py-2 border rounded-lg"
                                               required/>
                                        {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
                                    </div>

                                    <div className="form-group mb-4">
                                        <label className="block text-sm font-bold mb-2" htmlFor="subject">
                                            Subject
                                        </label>
                                        <input
                                            type="text"
                                            name="subject"
                                            id="subject"
                                            value={formData.subject}
                                            onChange={handleChange}
                                            className="w-full px-3 py-2 border rounded-lg"
                                            required
                                        />
                                        {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
                                    </div>

                                    <div className="form-group mb-4">
                                        <label className="block text-sm font-bold mb-2" htmlFor="message">
                                            Message
                                        </label>
                                        <textarea
                                            name="message"
                                            id="message"
                                            value={formData.message}
                                            onChange={handleChange}
                                            className="w-full px-3 py-2 border rounded-lg"
                                            required
                                        ></textarea>
                                        {errors.message && <p className="text-red-500 text-xs mt-1">{errors.message}</p>}
                                    </div>
                                    <div className="form-group p-3">
                                        <button type='submit' className="btn btn-primary font-bold w-96 text-center">
                                            <span className="text-2xl">Send</span></button>
                                        {submitStatus && <p className="mt-4 font-bold text-green-600 text-xl">{submitStatus}</p>}
                                        {submitFormError && <p className="mt-4 font-bold text-red-600 text-xl">{submitFormError}</p>}
    </div>
    <br/><br/>

    </form>

                            </div>
                </div>
            </div>






            </div>
</>);
};

export default ConcertDetails;
