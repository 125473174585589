import React, {useEffect} from 'react';
import {Link, NavLink, useLocation, useNavigate} from 'react-router-dom';
import SriSwamiji from "../assets/img/sri-swamiji.jpg";
import MusicTeam from "../assets/img/img-swamiji-musicteam.a3d9576.jpg";
import SGSBirds from "../assets/img/img-swamiji-wbird.3da8db2.jpg";
import SGSServices from "../assets/img/img-swamiji-charity.5b26576.jpg";
import SGSSlider1 from "../assets/img/sgsslider-1.png";
import CarouselImg1 from "../assets/img/howmusicheals_pick_2.jpg";
import YouTubeBanner from './YouTubeVideo';
import { useAuth } from '../contexts/AuthContext';
const Home = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { currentUser} = useAuth();
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');
        /*if (token) {
            console.log('Token updated ...')
            localStorage.setItem('token', token);
            // Remove token from URL
            window.history.replaceState({}, document.title, "/");
        }*/

    }, [location]);
    return (
        <div className="font-poppins max-w-full  mt-6 bg-white shadow-lg rounded-lg mb-20">
            <div className="container">
                <div className="row p-6">
                    <div className="max-w-full col-lg-12 col-md-12 mr-20">
                        <div className="float-right">
                            {/*{!currentUser && <NavLink
                            to="/login?nav=rys"
                            className="btn btn-primary text-right no-underline text-xl text-gray-600 hover:text-amber-600 px-3 py-2 rounded-md text-sm mb-6 font-medium"
                        >
                            Reserve Your Seat
                        </NavLink>}*/}
                            {/*{currentUser && <NavLink
                            to="/reserve-your-seat"
                            className="btn btn-primary text-right no-underline text-xl text-gray-600 hover:text-amber-600 px-3 py-2 rounded-md text-sm mb-6 font-medium"
                        >
                            Reserve Your Seat
                        </NavLink>}*/}
                        </div>
                    </div>
                        <div className='max-w-full col-lg-12 col-md-12 Swamiji_data'>
                            <YouTubeBanner className="max-w-full" videoId="wrYhoiRdeJ0"/>
                        </div>
                    </div>
                    <div className="row p-6">
                        <div className="col-md-6">
                            <img src={CarouselImg1} alt="first slide" className="carousel_imgs"/>
                    </div>
                        <div className='col-md-6 Swamiji_data'>
                            <h4>How Music Heals</h4>
                            <hr/>
                            <p>Central idea of music healing is to activate the true inner corewithin one's self and to
                                draw
                                it out
                                with one's life-breath - "praana", offering it in an expression of sound.It is this
                                expression,
                                which
                                helps to "cure" from within. Music is a universal language. Sri Swamiji's music is not
                                limited to
                                any
                                particular culture, time, place or people.</p>
                            <p><NavLink
                                to="/how-music-heals"
                                exact
                                activeClassName="no-underline text-white bg-gray-900"
                                className="no-underline text-xl text-gray-600 hover:text-amber-600 px-3 py-2 rounded-md text-sm font-medium"
                            >
                                More >>
                            </NavLink></p>

                        </div>
                    </div>
                <div className="row artists-class">

                    <div className='col-md-6 Swamiji_data'>
                        <h4>Celestial Message Troupe</h4>
                        <hr/>
                        <p>The Celestial Message Troupe consists of master musicians specializing in both South and
                            North
                            Indian instruments. Band members began their musical journeys early in life and have trained
                            in
                            classical Indian music. Respecting the musical genius of Sri Swamiji’s compositions, they
                            have
                            devoted their lives to his music.</p>

                        <p> The Healing and Meditation Music Concerts range from therapeutic to inspirational to
                            spiritual. The
                            Celestial Troupe have performed internationally with Sri Swamiji for over 25 years. This
                            includes
                            150 world concert tours of this uniquely rhythmic and melodious music. Experience music from
                            another dimension! A once-in-a-lifetime experience.</p>
                        <p><NavLink
                            to="/artists"
                            exact
                            activeClassName="no-underline text-white bg-gray-900"
                            className="no-underline text-xl text-gray-600 hover:text-amber-600 px-3 py-2 rounded-md text-sm font-medium"
                        >
                            More >>
                        </NavLink></p>

                    </div>
                    <div className="col-md-6">
                        <img src={SGSSlider1} alt="first slide" className="carousel_imgs"/>
                    </div>
                </div>
                <br/>
                <br/>
            </div>


        </div>
    );
};

export default Home;
