// src/components/AuthHandler.js
import React, { useEffect } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import { getAuth, getRedirectResult } from 'firebase/auth';
import { useAuth } from '../contexts/AuthContext';
import { toast } from 'react-toastify';

const AuthHandler = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { setCurrentUser } = useAuth();
    const [message, setMessage] = React.useState([]);

    useEffect(() => {
        const handleRedirectResult = async () => {
            try {
                const auth = getAuth();
                const result = await getRedirectResult(auth);
                if (result && result.user) {
                    setCurrentUser(result.user);
                    console.log('Current User from AuthHandler handleRedirectResult:', result.user)
                    message.push(`Current User from AuthHandler handleRedirectResult:${result.user.email}`)
                    toast.success('Successfully signed in!');
                    const nav = localStorage.getItem('page_redirect_from_mobile');
                    if (nav && nav === 'rys') {
                        //localStorage.setItem('page_redirect', nav);
                        localStorage.removeItem('page_redirect_from_mobile');
                        console.log('Redirecting to Reserve Your Sheet from AuthHandler handleRedirectResult:');
                        navigate('/reserve-your-seat');
                    } else {
                        console.log('Redirecting to Home from AuthHandler handleRedirectResult:');
                        navigate('/');
                    }
                     // or any other route you want to navigate to
                }
            } catch (error) {
                toast.error(`Error handling redirect result: ${error.message}`);
                console.error('Error handling redirect result: ', error.message);
                message.push(`Error handling redirect result: ${error.message}`)
            }
        };
        handleRedirectResult();
    }, [setCurrentUser, navigate]);

    return (
        <>
        {/*<p>Messages</p>
            {message && message.map((msg, index) => (
                <div key={index}>{msg}</div>
            ))}*/}
        </>
    );
};

export default AuthHandler;
