import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyBAFZI8to1To3ngyOnYgBvPxD74kLVGUaI",
    authDomain: "sgshoustonconcert2024.firebaseapp.com",
    projectId: "sgshoustonconcert2024",
    storageBucket: "sgshoustonconcert2024.appspot.com",
    messagingSenderId: "118506707772",
    appId: "1:118506707772:web:921f3c18a1b0ea77929c39",
    measurementId: "G-1NQ7EFMY9H"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export {auth};
export {analytics};
export {db};



