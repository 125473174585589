import React, { useEffect, useRef } from 'react';

const YouTubeBanner = ({ videoId }) => {
    const iframeRef = useRef(null);

    useEffect(() => {
        const iframe = iframeRef.current;
        if (iframe) {
            iframe.src = `https://www.youtube.com/embed/${videoId}?autoplay=1&playlist=${videoId}&loop=1&controls=0&mute=1&enablejsapi=1`;
        }
    }, [videoId]);

    const handleUnmute = () => {
        const iframe = iframeRef.current;
        if (iframe) {
            iframe.contentWindow.postMessage('{"event":"command","func":"unMute","args":""}', '*');
        }
    };

    return (
        <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', background: '#000' }}>
            <iframe
                ref={iframeRef}
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="YouTube Video"
            ></iframe>
            <button
                onClick={handleUnmute}
                style={{
                    position: 'absolute',
                    bottom: '10px',
                    left: '10px',
                    padding: '10px',
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    border: 'none',
                    cursor: 'pointer'
                }}
            >
                Unmute
            </button>
            <style jsx>{`
        iframe:hover {
          pointer-events: auto;
        }
        iframe {
          pointer-events: none;
        }
      `}</style>
        </div>
    );
};

export default YouTubeBanner;
