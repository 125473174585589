// src/components/ContactForm.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { db } from '../firebase-config';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import {useAuth} from "../contexts/AuthContext";
import QRCode from 'qrcode.react';
const ReserveYourSeat = () => {
    const {fixedAmount} = {fixedAmount: 25};
    const {preferredAmount} = {preferredAmount: 500};
    const {premiumAmount} = {premiumAmount: 1000};
    const {vipAmount} = {vipAmount: 2500};
    const [processing, setProcessing] = useState(false);
    const [maxTickets, setMaxTickets] = useState(6);
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneno: '',
        notickets: '',
        amount: fixedAmount,
        currency: 'USD'
    });

    const { currentUser } = useAuth();
    const [readOnlyAmount, setReadOnlyAmount] = useState(true);
    const [paymentUrl, setPaymentUrl] = useState(null);
    const [errors, setErrors] = useState({});
    const [seating, setSeating] = useState('General Seating');
    const [ticketid, setTicketId] = useState('');
    const [qrCodeDataURL, setQRCodeDataURL] = useState('');
    const [usingMobile, setUsingMobile] = useState(false);
    const [ticketOptions, setTicketOptions] = useState([]);
    const [defaultValue, setDefaultValue] = useState(null);
    const [ticketAmount, setTicketAmount] = useState(0);
    useEffect(() => {
        setFormData({ ...formData, email: currentUser.email, name: currentUser.displayName, amount: fixedAmount, notickets: 1 });
        setSeating('General Seating');
        setTicketOptions(Array.from({length: maxTickets}, (_, i) => i + 1));
        setDefaultValue(maxTickets);
        setTimeout(() => {
            setLoading(false);
        },1000);
    },[]);

    useEffect(() => {
        setTimeout(() => {
            const canvas = document.getElementById('qrCodeCanvas');
            if (!canvas) return;
            const dataURL = canvas.toDataURL();
            setQRCodeDataURL(dataURL);
        },1000);
    }, [ticketid]);
    useEffect(() => {
        const updateTicketOptions = () => {
            setLoading(true);
            setDefaultValue(null);
            const options = Array.from({ length: maxTickets }, (_, i) => i + 1);
            setTicketOptions(options);
            if(seating === 'General Seating'){
                setDefaultValue(options[0]);
            } else {
                setDefaultValue(options[options.length - 1]);
            }
            setLoading(false);
        };

        updateTicketOptions();
    }, [maxTickets]);
    const handleGS = (e) => {
        setSeating('General Seating');
        //setLoading(true);
        setFormData({ ...formData, amount: fixedAmount, notickets: 1})
        setMaxTickets(6);
        setReadOnlyAmount(true)
        //setLoading(false);
    }

    const handlePreferredSeating = (e) => {
        setSeating('Preferred Seating');
        //setLoading(true);
        setFormData({ ...formData, amount: preferredAmount, notickets: 4})
        setReadOnlyAmount(true);
        setMaxTickets(4);
        //setFormData({ ...formData, amount: 0 })
    }

    const handlePremiumSeating = (e) => {
        setSeating('Premium Seating');
        //setLoading(true);
        setReadOnlyAmount(true);
        setFormData({ ...formData, amount: premiumAmount, notickets: 6})
        setMaxTickets(6);
    }

    const handleVipSeating = (e) => {
        setSeating('VIP Seating');
        //setLoading(true);
        setReadOnlyAmount(true);
        setFormData({ ...formData, amount: vipAmount, notickets: 6})
        setMaxTickets(6);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => {
            const updatedFormData = { ...prevFormData, [name]: value };
            if (name === 'notickets' && seating === 'General Seating') {
                updatedFormData.amount = fixedAmount * value;
            }
            return updatedFormData;
        });
    };

    const generateRandomString = (length) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            result += characters.charAt(randomIndex);
        }
        return result;
    };

    const validatePhoneNumber = (number) => {
        // Define the phone number pattern (example: US phone number pattern)
        const phoneNumberPattern = /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/;

        return phoneNumberPattern.test(number);
    };
    const validate = () => {
        const errors = {};
        if (!formData.name.trim()) errors.name = 'Name is required';
        if (!formData.email) errors.email = 'Email is required';
        if (!formData.phoneno) errors.phoneno = 'Phone number is required';
        if (!validatePhoneNumber(formData.phoneno)) errors.phoneno = 'Phone number is invalid';
        if (!formData.notickets||formData.notickets<=0) errors.notickets = 'No. of tickets is required';
        if (!formData.amount || formData.amount <= 0) errors.amount = 'Amount must be greater than zero';
        return errors;
    };

    useEffect(() => {
        if (!loading) {
            setTimeout(() => {
                const selectElement = document.getElementById('ticket-select');
                selectElement.value = defaultValue;
            }, 500); // Adjust the delay as needed
        }
    }, [loading, defaultValue]);


    useEffect(() => {
        const updateTicketAmount = () => {
            if (seating === 'General Seating') {
                setTicketAmount((formData.amount / formData.notickets) * 100);
                console.log('Ticket Amount: (GS) ', ticketAmount);
            } else {
                setTicketAmount(formData.amount * 100);
                console.log('Ticket Amount: (PS, PrS, VIP) ', ticketAmount);
            }
        }
        updateTicketAmount();
    },[formData.amount, formData.notickets, seating]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        setProcessing(true);
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            setProcessing(false);
            return;
        }
        setErrors({});
        try {
            const name = formData.name;
            const firstName = name.split(' ')[0];
            const lastName = name.split(' ')[1] || name.split(' ')[0];
            //const uniqueId = generateRandomString(15);
            localStorage.setItem('name', name);
            localStorage.setItem('email', currentUser.email);
            localStorage.setItem('phoneno', formData.phoneno);
            localStorage.setItem('notickets', formData.notickets);
            localStorage.setItem('amount', formData.amount);
            localStorage.setItem('currency', formData.currency);
            localStorage.setItem('seating', seating);
            setTimeout(async ()=> {
                const docRef = await addDoc(collection(db, 'reserve-your-seat'), {
                    firstname: firstName,
                    lastname: lastName,
                    email: currentUser.email,
                    phoneno: formData.phoneno,
                    notickets: formData.notickets,
                    amount: formData.amount*100, // Clover expects amount in cents
                    currency: formData.currency,
                    seating: seating,
                    status: 'pending',
                    host: window.location.origin
                });
                console.log('Document written with ID: ', docRef.id);
                setTicketId(docRef.id);
                localStorage.setItem('userbookingid', docRef.id);


                setTimeout(async () => {
                    const paymentResponse = await axios.post('/create-payment', {
                        firstname: firstName,
                        lastname: lastName,
                        email: currentUser.email,
                        phoneno: formData.phoneno,
                        seating: seating,
                        amount: ticketAmount, // Clover expects amount in cents
                        currency: formData.currency,
                        notickets: formData.notickets,
                        userbookingid: docRef.id,
                        successfulUrl: window.location.origin + '/payment-successful',
                        cancelUrl: window.location.origin + '/payment-cancelled',
                        failureUrl: window.location.origin + '/payment-failed',
                        qrCode: qrCodeDataURL
                    });

                    setPaymentUrl(paymentResponse.data.href);
                    console.log('Payment session: ', paymentResponse.data.href);
                    const docRef2 = await addDoc(collection(db, 'payment-sessions'), {
                        userbookingid: docRef.id,
                        paymenturl: paymentResponse.data.href,
                        status: 'pending',
                        email: currentUser.email,
                        host: window.location.origin,
                        created_at: JSON.stringify(paymentResponse.data.createdTime),
                        expiration: JSON.stringify(paymentResponse.data.expirationTime),
                        checkoutSessionId: paymentResponse.data.checkoutSessionId
                    });
                    window.location.href = paymentResponse.data.href;
                }, 3000);
            }, 3000);
        } catch (error) {
            setProcessing(false);
            console.error('Error processing payment: ', error);
        }
    };

    const isMobile = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        return (/android|iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream);
    };

    useEffect(() => {
        setUsingMobile(isMobile());
    },[]);

    return (
        <>
            <div className="mb-48">
                {!usingMobile && (<div className="text-center mt-6 mb-6">
                <button onClick={handleGS}
                        className=" w-48 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mr-5 mb-2 rounded">General
                    Seating
                </button>
                <button onClick={handlePreferredSeating}
                        className="w-48 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mr-5 mb-2 rounded">Preferred
                    Seating
                </button>
                <button onClick={handlePremiumSeating}
                        className="w-48 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mr-5 mb-2 rounded">Premium
                    Seating
                </button>
                <button onClick={handleVipSeating}
                        className="w-48 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mr-5 mb-2 rounded">VIP
                    Seating
                </button>
            </div>)}

                {usingMobile && (<div className="text-sm text-center mt-3 mb-3">
                    <p className="mb-2 ml-2"><button onClick={handleGS}
                            className=" w-48 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-1 mr-3 mb-1 rounded">General
                        Seating
                    </button>
                   <button onClick={handlePreferredSeating}
                            className="w-48 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-1 mr-3 mb-1 rounded ">Preferred
                        Seating
                    </button>
                        <button onClick={handlePremiumSeating}
                            className="w-48 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-1 mr-3 mb-1 rounded">Premium
                        Seating
                    </button>
                    <button onClick={handleVipSeating}
                            className="w-48 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-1 mr-3 mb-1 rounded">VIP
                        Seating
                </button></p>
                </div>)}

            <h1 className="text-center text-amber-600 rounded-3 text-3xl font-bold mt-6">Reserve Your Seat
                : {seating}</h1>
                <div className="mt-6">
                    <div>
                        <p className="mt-6 mb-6 text-red-600 font-bold text-4xl text-center">Currently registration for
                            concert disabled <br/>due to unforeseen weather conditions</p>
                    </div>
                    <form onSubmit={handleSubmit} className="invisible max-w-md mx-auto p-4 shadow-md rounded-lg">
                        <div className="mb-4">
                            <label className="block text-sm font-bold mb-2" htmlFor="name">
                                Name
                            </label>
                            <input
                                type="text"
                                name="name"
                                id="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border rounded-lg"
                                required
                            />
                            {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-bold mb-2" htmlFor="email">
                                Email
                            </label>
                            <input
                                type="email"
                                name="email"
                                id="email"
                                value={currentUser.email}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border rounded-lg"
                                readOnly
                            />
                            {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-bold mb-2" htmlFor="email">
                                Phone Number
                            </label>
                            <input
                                type="text"
                                name="phoneno"
                                id="phoneno"
                                value={formData.phoneno}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border rounded-lg"
                                required
                            />
                            {errors.phoneno && <p className="text-red-500 text-xs mt-1">{errors.phoneno}</p>}
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-bold mb-2" htmlFor="notickets">

                                No. of Tickets
                            </label>
                            {loading ? (
                                <div className="loader-mask">Loading...</div>
                            ) : (
                                <select
                                    name="notickets"
                                    id="ticket-select"
                                    value={formData.notickets}
                                    onChange={handleChange}
                                    className="w-full px-3 py-2 border rounded-lg"
                                    required
                                >
                                    {ticketOptions.map(number => (
                                        <>
                                            <option selected key={number} value={number}>
                                                {number}
                                            </option>
                                            )
                                        </>
                                    ))}
                                </select>)}
                            {errors.notickets && <p className="text-red-500 text-xs mt-1">{errors.notickets}</p>}
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-bold mb-2" htmlFor="amount">
                                Amount (USD)
                            </label>
                            <input
                                type="number"
                                name="amount"
                                id="amount"
                                value={formData.amount}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border rounded-lg"
                                readOnly={readOnlyAmount}
                                required
                            />
                            {errors.amount && <p className="text-red-500 text-xs mt-1">{errors.amount}</p>}
                        </div>
                        {/*{!processing && <button
                        type="submit"
                        className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
                    >
                        Submit
                    </button>}*/}
                        {processing && (<div className="text-gray-300 font-bold">
                            Please wait while we process your request ...
                        </div>)}
                    </form>

                </div>
                <div className="-left-96 text-center mt-6 float-right hidden mr-200 w-96 h-96">
                    {ticketid != '' &&
                        <QRCode id="qrCodeCanvas" value={`https://concert.hdyc.org/get-details-old?ref=${ticketid}`}/>}
                </div>
            </div>
        </>
    );
};

export default ReserveYourSeat;
