// src/components/SignInWithGoogle.js
import React, {useEffect, useState} from 'react';
import { getAuth, GoogleAuthProvider, signInWithPopup,signInWithRedirect,getRedirectResult } from 'firebase/auth';
import { useAuth } from '../contexts/AuthContext';
import './SignInWithGoogle.css';
import SignInWithEmail from "./SignInWithEmail";
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
const SignInWithGoogle = () => {
    const location = useLocation();
    const { setCurrentUser } = useAuth();
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    const navigate = useNavigate();
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const nav = params.get('nav');
        if (nav && nav === 'rys') {
            if (!isMobile()) {
                localStorage.setItem('page_redirect', nav);
            } else {
                localStorage.setItem('page_redirect_from_mobile', nav);
            }

        }
    },[])
    const signInWithGoogle = async () => {

        /*signInWithPopup(auth, provider)
            .then((result) => {
                console.log('User signed in:', result.user);
                //window.location.href = '/';
            })
            .catch((error) => {
                console.error('Error signing in with Google:', error);
            });*/
        setProcessing(true);
        if (isMobile()) {
            try {
                //await signInWithRedirect(auth, provider);
                const result = await signInWithPopup(auth, provider);
                if(result && result.user) {
                    setCurrentUser(result.user);
                    navigate('/reserve-your-seat');
                }
            } catch (error) {
                console.error('Error during signInWithRedirect:', error);
                setError(error);
                toast.error(`Error during signInWithRedirect: ${error.message}`);
            }
        } else {
            const result = await signInWithPopup(auth, provider);
            if(result && result.user) {
                setCurrentUser(result.user);
                navigate('/reserve-your-seat');
                //toast.error(`Error during signInWithPopup: ${error.message}`);
            }
        }
        setProcessing(false);
    };

    const isMobile = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        return (/android|iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream);
    };


    return (
        <>
            <div className="mb-28">
                <div>
                <SignInWithEmail/>
            </div>
            <div className="text-center mt-6">
        <button className="google-login-button" onClick={signInWithGoogle}>
            <img src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" width="32" alt="Google logo" />
            Sign in with Google
        </button>
                {processing && <p className="text-amber-800 font-bold">Please wait while processing...</p>}
                {error && <p className="text-red-800 font-bold">{error.message}</p>}
            </div>
            </div>
            <ToastContainer />
        </>
    );
};

export default SignInWithGoogle;
