import React, { useState, useEffect} from 'react';
import TitleImg from "../assets/img/artists.jpg";
import JaitraVaranasi from "../assets/img/Jaitra Varanasi.jpg";
import ManiTadepalli from "../assets/img/Mni Tadepalli.jpg";
import ShankarRamesh from "../assets/img/Shanker Ramesh.jpg";
import MaheshBhat from "../assets/img/Mahesh Bhat.jpg";
import AmarnathMaharaj from "../assets/img/AmarnathMaharaj.jpg";
import ChandanKumar from '../assets/img/ChandanKumar.jpg';
const Artists = () => {

    const isMobile = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        return (/android|iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream);
    };
    return (
        <>
            <img className="w-full" src={TitleImg} alt="first slide" />
                <h3 className="page-title text-center text-amber-600 font-bold text-4xl p-3">Artists</h3>
                <div className="font-poppins w-full font-poppins body-alignment">

                    {!isMobile() &&<div className="max-w-full bg-gray-100 artists flex justify-between p-3">
                        <div className="w-64 team">
                            <h4 className="font-bold text-red-600 w-64 text-xl">Jaitra Varanasi</h4>
                            <h3 className="font-bold text-xl w-64 text-amber-600">(Violin)</h3>
                            <br/>
                            <div className="bg-yellow-400 w-64 p-2 mb-3">
                                <img src={JaitraVaranasi} alt="Jaitra Varanasi"
                                     className="h-64 w-64 border border-gray-500 border-4 rounded-lg"/>
                            </div>
                            <p className=' w-64 mb-6'>The violin has been an important instrument in Indian music
                                for over 200 years. The
                                Indian
                                violin is tuned and
                                held differently when played as compared to the Western violin.</p>
                        </div>
                        <div className="w-64 team">
                            <h4 className="font-bold text-red-600 w-64 text-xl">Mani Tadepalli</h4>
                            <h3 className="font-bold text-xl w-64 text-amber-600">(Keyboard)</h3>
                            <br/>
                            <div className="bg-yellow-400 w-64 p-2 mb-3">
                                <img src={ManiTadepalli} alt="Mni Tadepalli"
                                     className="h-64 w-64 border border-gray-500 border-4 rounded-lg"/>
                            </div>
                            <p className='artists_data w-64 mb-6'>Traditional Carnatic music includes keyboard melodies and
                                accompaniment. The modern use
                                of
                                synthesizers has
                                allowed incorporation of the traditional half- and semi-tones of Indian classical
                                music.</p>
                        </div>
                        <div className="w-64 team">
                            <h4 className="font-bold text-red-600 w-64 text-xl">Shankar Ramesh</h4>
                            <h3 className="font-bold text-xl w-64 text-amber-600">(Mridangam)</h3>
                            <br/>
                            <div className="bg-yellow-400 w-64 p-2 mb-3">
                                <img src={ShankarRamesh} alt="Shankar Ramesh"
                                     className="h-64 w-64 border border-gray-500 border-4 rounded-lg"/>
                            </div>
                            <p className='artists_data w-64 mb-6'>The mridangam is an ancient double-headed barrel drum
                                played with the hands. Known as
                                “the king” of Indian
                                percussion, it is the main drum used in Carnatic(Southern) Indian music.</p>
                        </div>
                        <div className="w-64 team">
                            <h4 className="font-bold text-red-600 w-64 text-xl">Amarnath Maharaj</h4>
                            <h3 className="font-bold text-xl w-64 text-amber-600">(Tabla)</h3>
                            <br/>
                            <div className="bg-yellow-400 w-64 p-2 mb-3">
                                <img src={AmarnathMaharaj} alt="Amarnath Maharaj"
                                     className="h-64 w-64 border border-gray-500 border-4 rounded-lg"/>
                            </div>
                            <p className='artists_data w-64 mb-6'>The tabla is known as “the queen” of Indian percussion and
                                is the principal percussion
                                instrument in
                                Hindustani(Northern) Indian music. The Tabla consists of a pair of small, tuned drums,
                                one larger than the
                                other,
                                played with the hands.
                            </p>
                        </div>

                        <div className="w-64 team">
                            <h4 className="font-bold text-red-600 text-xl">Chandan Kumar</h4>
                            <h3 className="font-bold text-xl text-amber-600">(Flute)</h3>
                            <br/>
                            <div className="bg-yellow-400 w-64 p-2 mb-3">
                                <img src={ChandanKumar} alt="Amarnath Maharaj"
                                     className="h-64 w-64 border border-gray-500 border-4 rounded-lg"/>
                            </div>
                            <p className='artists_data w-64 mb-6'>The flute is a member of a
                                family of musical instruments in the woodwind group.
                                Like all woodwinds, flutes are aerophones, producing sound with
                                a vibrating column of air. Flutes produce sound when the player's
                                air flows across an opening.
                            </p>
                        </div>

                    </div>}


                    {isMobile() &&<div className=" text-center  max-w-md max-w-sm max-w-lg flex flex-col md:flex-row md:justify-between space-y-4 md:space-y-0 md:space-x-4 bg-gray-100 artists p-3">
                        <div className="w-64 flex-1 team">
                            <h4 className="font-bold text-red-600 w-64 text-xl">Jaitra Varanasi</h4>
                            <h3 className="font-bold text-xl w-64 text-amber-600">(Violin)</h3>
                            <br/>
                            <div className="bg-yellow-400 w-64 p-2 mb-3">
                                <img src={JaitraVaranasi} alt="Jaitra Varanasi"
                                     className="h-64 w-64 border border-gray-500 border-4 rounded-lg"/>
                            </div>
                            <p className=' w-64 mb-6'>The violin has been an important instrument in Indian music
                                for over 200 years. The
                                Indian
                                violin is tuned and
                                held differently when played as compared to the Western violin.</p>
                        </div>
                        <div className="w-64 flex-1 team">
                            <h4 className="font-bold text-red-600 w-64 text-xl">Mani Tadepalli</h4>
                            <h3 className="font-bold text-xl w-64 text-amber-600">(Keyboard)</h3>
                            <br/>
                            <div className="bg-yellow-400 w-64 p-2 mb-3">
                                <img src={ManiTadepalli} alt="Mni Tadepalli"
                                     className="h-64 w-64 border border-gray-500 border-4 rounded-lg"/>
                            </div>
                            <p className='artists_data w-64 mb-6'>Traditional Carnatic music includes keyboard melodies and
                                accompaniment. The modern use
                                of
                                synthesizers has
                                allowed incorporation of the traditional half- and semi-tones of Indian classical
                                music.</p>
                        </div>
                        <div className="w-64 flex-1 team">
                            <h4 className="font-bold text-red-600 w-64 text-xl">Shankar Ramesh</h4>
                            <h3 className="font-bold text-xl w-64 text-amber-600">(Mridangam)</h3>
                            <br/>
                            <div className="bg-yellow-400 w-64 p-2 mb-3">
                                <img src={ShankarRamesh} alt="Shankar Ramesh"
                                     className="h-64 w-64 border border-gray-500 border-4 rounded-lg"/>
                            </div>
                            <p className='artists_data w-64 mb-6'>The mridangam is an ancient double-headed barrel drum
                                played with the hands. Known as
                                “the king” of Indian
                                percussion, it is the main drum used in Carnatic(Southern) Indian music.</p>
                        </div>
                        <div className="w-64 flex-1 team">
                            <h4 className="font-bold text-red-600 w-64 text-xl">Amarnath Maharaj</h4>
                            <h3 className="font-bold text-xl w-64 text-amber-600">(Tabla)</h3>
                            <br/>
                            <div className="bg-yellow-400 w-64 p-2 mb-3">
                                <img src={AmarnathMaharaj} alt="Amarnath Maharaj"
                                     className="h-64 w-64 border border-gray-500 border-4 rounded-lg"/>
                            </div>
                            <p className='artists_data w-64 mb-6'>The tabla is known as “the queen” of Indian percussion and
                                is the principal percussion
                                instrument in
                                Hindustani(Northern) Indian music. The Tabla consists of a pair of small, tuned drums,
                                one larger than the
                                other,
                                played with the hands.
                            </p>
                        </div>

                        <div className="w-64 flex-1 team">
                            <h4 className="font-bold text-red-600 text-xl">Chandan Kumar</h4>
                            <h3 className="font-bold text-xl text-amber-600">(Flute)</h3>
                            <br/>
                            <div className="bg-yellow-400 w-64 p-2 mb-3">
                                <img src={ChandanKumar} alt="Amarnath Maharaj"
                                     className="h-64 w-64 border border-gray-500 border-4 rounded-lg"/>
                            </div>
                            <p className='artists_data w-64 mb-6'>The flute is a member of a
                                family of musical instruments in the woodwind group.
                                Like all woodwinds, flutes are aerophones, producing sound with
                                a vibrating column of air. Flutes produce sound when the player's
                                air flows across an opening.
                            </p>
                        </div>

                    </div>}
                </div>
        </>);
};

export default Artists;
