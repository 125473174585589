import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ProcessDetails from "./ProcessDetails";
import {Link, NavLink, useLocation, useNavigate} from 'react-router-dom';
const PaymentSuccessful = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [amount, setAmount] = useState(0);
    const [currency, setCurrency] = useState('USD');
    const [seating, setSeating] = useState('');
    const [notickets, setNoTickets] = useState(0);
    const [userbookingid, setUserBookingId] = useState('');
    const [valid, setValid] = useState(false);
    const location = useLocation();
    const [reference, setReference] = useState(null);
    const [reference2, setReference2] = useState(null);
    const processStatus = 'success';
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        setReference(params.get('ref'));
        setReference2(params.get('ref2'));

        const name = localStorage.getItem('name');
        const email = localStorage.getItem('email');
        const amount = localStorage.getItem('amount');
        const currency = localStorage.getItem('currency');
        const seating = localStorage.getItem('seating');
        const notickets = localStorage.getItem('notickets');
       setUserBookingId(localStorage.getItem('userbookingid'));
        setName(name);
        setEmail(email);
        setAmount(amount);
        setCurrency(currency);
        setSeating(seating);
        setNoTickets(notickets);
        //setUserBookingId(userbookingid);
        if (amount && currency && seating && notickets) {
            setValid(true);
        } else {
            setValid(false);
        }
        localStorage.removeItem('amount');
        localStorage.removeItem('currency');
        localStorage.removeItem('seating');
        localStorage.removeItem('notickets');
    }, []);
    return (
        <div className="mb-20">
            <div className="flex items-center justify-center bg-white">
                <div className="bg-white p-8 rounded shadow-md w-128">
                    {valid && <h1 className="text-3xl mt-6 font-bold text-green-600">Payment Successful</h1>}
                    {valid && <p className="text-left text-lg text-gray-700">Thank you for your payment</p>}
                    {!valid && <h1 className="text-3xl mt-6 font-bold text-red-600 mb-6">Page expired</h1>}
                    {!valid && <p className="text-left text-lg text-red-600">For any clarification, please send an email with<br/> your booking id to info@hdyc.org</p>}
                    <p className="text-left text-lg text-amber-700">Your booking ID is: {userbookingid}</p>
                    <p className="text-left text-lg text-gray-700">Name: {name}</p>
                    <p className="text-left text-lg text-gray-700">Email: {email}</p>
                    <p className="text-left text-lg text-gray-700">Seating: {seating}</p>
                    <p className="text-left text-lg text-gray-700">No. of tickets: {notickets}</p>
                    <p className="text-left text-lg text-gray-700">Amount: {amount}</p>
                    <p className="text-left text-lg text-gray-700">Currency: {currency}</p>
                    {valid && reference && <ProcessDetails  reference={reference} reference2={reference2} status={processStatus} ticketid={userbookingid} email={email}/>}
                </div>

            </div>
        </div>
    );
};

export default PaymentSuccessful;
