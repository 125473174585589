import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './NavBar.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { FaBars, FaTimes } from 'react-icons/fa';

const NavBar = () => {
    const navigate = useNavigate();
    const { currentUser, logOut } = useAuth();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const handleLogout = () => {
        logOut();
        navigate('/login');
    };

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <nav className="bg-amber-600 p-4 font-bold">
            <div className="container mx-auto flex justify-between items-center">
                <div className="text-xl text-gray-300 rounded-5">HDYC</div>
                <div className="hidden md:flex space-x-4">
                    <NavLink
                        to="/"
                        exact
                        className={({ isActive }) =>
                            "no-underline text-xl px-3 py-2 rounded-md text-sm font-medium " +
                            (isActive ? "text-amber-700 bg-yellow-300" : "text-gray-300 hover:text-white")
                        }
                    >
                        Home
                    </NavLink>
                    <NavLink
                        to="/the-composer"
                        exact
                        className={({ isActive }) =>
                            "no-underline text-xl px-3 py-2 rounded-md text-sm font-medium " +
                            (isActive ? "text-amber-700 bg-yellow-300" : "text-gray-300 hover:text-white")
                        }
                    >
                        The Composer
                    </NavLink>
                    <NavLink
                        to="/how-music-heals"
                        exact
                        className={({ isActive }) =>
                            "no-underline text-xl px-3 py-2 rounded-md text-sm font-medium " +
                            (isActive ? "text-amber-700 bg-yellow-300" : "text-gray-300 hover:text-white")
                        }
                    >
                        How Music Heals
                    </NavLink>
                    <NavLink
                        to="/artists"
                        exact
                        className={({ isActive }) =>
                            "no-underline text-xl px-3 py-2 rounded-md text-sm font-medium " +
                            (isActive ? "text-amber-700 bg-yellow-300" : "text-gray-300 hover:text-white")
                        }
                    >
                        Artists
                    </NavLink>
                    {/*{!currentUser &&
                        <NavLink
                            to="/login?nav=rys"
                            exact
                            className={({ isActive }) =>
                                "no-underline text-xl px-3 py-2 rounded-md text-sm font-medium " +
                                (isActive ? "text-amber-700 bg-yellow-300" : "text-gray-300 hover:text-white")
                            }
                        >
                            Reserve Your Seat
                        </NavLink>
                    }*/}
                    {currentUser && currentUser.role === 'admin' && (
                        <NavLink
                            to="/admin/users"
                            className={({ isActive }) =>
                                "no-underline text-xl px-3 py-2 rounded-md text-sm font-medium " +
                                (isActive ? "text-amber-700 bg-yellow-300" : "text-gray-300 hover:text-white")
                            }
                        >
                            Users List
                        </NavLink>
                    )}
                    {currentUser && (
                        <>
                            {/* <NavLink
                                to="/reserve-your-seat"
                                exact
                                className={({ isActive }) =>
                                    "no-underline text-xl px-3 py-2 rounded-md text-sm font-medium " +
                                    (isActive ? "text-amber-700 bg-yellow-300" : "text-gray-300 hover:text-white")
                                }
                            >
                                Reserve Your Seat
                            </NavLink>*/}
                            <NavLink
                                to="/profile"
                                className={({ isActive }) =>
                                    "no-underline text-xl px-3 py-2 rounded-md text-sm font-medium " +
                                    (isActive ? "text-amber-700 bg-yellow-300" : "text-gray-300 hover:text-white")
                                }
                            >
                                Profile
                            </NavLink>
                            <button
                                onClick={handleLogout}
                                className="no-underline text-xl text-gray-300 hover:text-white px-3 rounded-md text-sm font-medium"
                            >
                                Logout
                            </button>
                        </>
                    )}
                    {!currentUser && (
                        <NavLink
                            to="/login"
                            className={({ isActive }) =>
                                "no-underline text-xl px-3 py-2 rounded-md text-sm font-medium " +
                                (isActive ? "text-amber-700 bg-yellow-300" : "text-gray-300 hover:text-white")
                            }
                        >
                            Login
                        </NavLink>
                    )}
                    <NavLink
                        to="/concert-details"
                        exact
                        className={({ isActive }) =>
                            "no-underline text-xl px-3 py-2 rounded-md text-sm font-medium " +
                            (isActive ? "text-amber-700 bg-yellow-300" : "text-gray-300 hover:text-white")
                        }
                    >
                        Contact Us
                    </NavLink>
                </div>
                <div className="md:hidden">
                    <button onClick={toggleMobileMenu} className="text-gray-300 focus:outline-none">
                        {isMobileMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
                    </button>
                </div>
            </div>
            {isMobileMenuOpen && (
                <ul className="md:hidden flex flex-col items-center space-y-4 mt-4">
                    <li>
                        <NavLink
                            to="/"
                            exact
                            activeClassName="no-underline text-white bg-gray-900"
                            className="no-underline text-xl text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                            onClick={toggleMobileMenu}
                        >
                            Home
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/the-composer"
                            exact
                            activeClassName="no-underline text-white bg-gray-900"
                            className="no-underline text-xl text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                            onClick={toggleMobileMenu}
                        >
                            The Composer
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/how-music-heals"
                            exact
                            activeClassName="no-underline text-white bg-gray-900"
                            className="no-underline text-xl text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                            onClick={toggleMobileMenu}
                        >
                            How Music Heals
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/artists"
                            exact
                            activeClassName="no-underline text-white bg-gray-900"
                            className="no-underline text-xl text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                            onClick={toggleMobileMenu}
                        >
                            Artists
                        </NavLink>
                    </li>
                    {!currentUser && <li>
                        <NavLink
                            to="/login?nav=rys"
                            exact
                            activeClassName="no-underline text-white bg-gray-900"
                            className="no-underline text-xl text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                            onClick={toggleMobileMenu}
                        >
                            Reserve Your Seat
                        </NavLink>
                    </li>}
                    {currentUser && currentUser.role === 'admin' && (
                        <li>
                            <NavLink
                                to="/admin/users"
                                activeClassName="no-underline text-white bg-gray-900"
                                className="no-underline text-xl text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                onClick={toggleMobileMenu}
                            >
                                Users List
                            </NavLink>
                        </li>
                    )}
                    {currentUser && (
                        <>
                            <li>
                                <NavLink
                                    to="/reserve-your-seat"
                                    exact
                                    activeClassName="no-underline text-white bg-gray-900"
                                    className="no-underline text-xl text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                    onClick={toggleMobileMenu}
                                >
                                    Reserve Your Seat
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/profile"
                                    activeClassName="no-underline text-white bg-gray-900"
                                    className="no-underline text-xl text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                    onClick={toggleMobileMenu}
                                >
                                    Profile
                                </NavLink>
                            </li>
                            <li>
                                <button
                                    onClick={() => {
                                        handleLogout();
                                        toggleMobileMenu();
                                    }}
                                    className="no-underline text-xl text-gray-300 hover:text-white px-3 rounded-md text-sm font-medium"
                                >
                                    Logout
                                </button>
                            </li>
                        </>
                    )}
                    {!currentUser && (
                        <li>
                            <NavLink
                                to="/login"
                                activeClassName="no-underline text-white bg-gray-900"
                                className="no-underline text-xl text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                onClick={toggleMobileMenu}
                            >
                                Login
                            </NavLink>
                        </li>
                    )}
                    <li>
                        <NavLink
                            to="/concert-details"
                            exact
                            activeClassName="no-underline text-white bg-gray-900"
                            className="no-underline text-xl text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                            onClick={toggleMobileMenu}
                        >
                            Contact Us
                        </NavLink>
                    </li>
                </ul>
            )}
            {currentUser && <div className="float-right text-gray-300">Welcome {currentUser && currentUser.email}</div>}
        </nav>
    );
};

export default NavBar;
