import react, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import React from "react";
const FriendsAndFamily = () => {
    const { currentUser } = useAuth();
    const [family, setFamily] = useState([]);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [notickets, setNoTickets] = useState('');
    const [status, setStatus] = useState('Pending');
    const [error, setError] = useState({});
    const [maxTickets, setMaxTickets] = useState(6);
    const [formCanBeValidated, setFormCanBeValidated] = useState(false);
    const [message, setMessage] = useState('');
    const [disableBtn, setDisableBtn] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneno: '',
        notickets: '',
        status: 'Submitted'
    });
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
        setFormCanBeValidated(true);
    }

    useEffect(() => {
        validate();
    },[formData])

    const validatePhoneNumber = (number) => {
        // Define the phone number pattern (example: US phone number pattern)
        const phoneNumberPattern = /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/;

        return phoneNumberPattern.test(number);
    };
    const validate = () => {
        if(!formCanBeValidated) return;
        let errors = {};
        let invalidForm = false
        if(formData.name === ''){
           errors = {...errors, name: 'Name is required'};
           invalidForm = true;
        }
        if(formData.email === ''){
            errors = {...errors, email: 'Email is required'};
            invalidForm = true;
        }
        if (!formData.phoneno === ''){
            errors = {...errors, phoneno : 'Phone number is required'};
            invalidForm = true;
        }
        if (!validatePhoneNumber(formData.phoneno)){
            errors= {...errors, phoneno : 'Phone number is invalid'};
            invalidForm = true;
        }
        const validEmail = new RegExp(
            /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/
        );
        if(!validEmail.test(formData.email)){
            errors = {...errors, email: 'Email is invalid'};
            invalidForm = true;
        }
        if(formData.notickets === ''){
            errors = {...errors, notickets: 'No. of Tickets is required'};
            invalidForm = true;
        }
        if(parseInt(formData.notickets) < 1){
            errors = {...errors, notickets: 'No. of Tickets must be greater than 0'};
            invalidForm = true;
        }
        setError(errors);
        return !invalidForm;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(!validate()){
            setMessage('Please fill data or correct the errors')
            return;
        }
        setError({});
        setMessage('Please wait while we process your request');
        setDisableBtn(true)
        let submittedUser = '';
        if (currentUser) {
            submittedUser =  currentUser.email;
        }
        axios.post('/friends-and-family-requests',{
            email: submittedUser,
            name: formData.name,
            email2: formData.email,
            phoneno: formData.phoneno,
            notickets: formData.notickets,
            status: formData.status
        })
        .then(res => {
            setMessage('VIP Member Added Successfully');
            setFormCanBeValidated(false)
            setFormData({
                name: '',
                email: '',
                phoneno: '',
                notickets: '',
                status: 'Submitted'
            });
            setFamily(res.data);
            setTimeout(() => {
                setMessage('');
                setDisableBtn(false);
            },5000);
        })
        .catch(err => {
            console.log(err);
            setMessage('Request Submission Failed');
            setTimeout(() => {
                setMessage('');
                setDisableBtn(false);
            },5000);
        })
    }

    const isMobile = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        return (/android|iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream);
    };


    return (
        <div className="mt-3 mb-20">
            <div>
                <h1 className="text-center font-bold text-4xl text-amber-600">Friends And Family</h1>
                <div className="min-w-128 w-128 flex justify-center items-start p-3">
                    <div className=" mt-3 mb-3 p-5 shadow-md shadow-lg rounded-3">
                        <div className="text-center">
                            <h2 className="text-2xl font-bold text-amber-600 mt-3 mb-3">Details of Friends and Family</h2>
                        </div>
                        <p>
                            <label className="block text-sm font-bold mb-2" htmlFor="name">Name:</label>
                            <input type="text" name="name" value={formData.name} onChange={handleChange}
                                   placeholder="Name" className="border border-gray-300 p-2"/>
                            {error.name && <p className="text-red-500">{error.name}</p>}
                        </p>
                        <p>
                            <label className="block text-sm font-bold mb-2" htmlFor="email">Email:</label>
                            <input type="text" name="email" value={formData.email} onChange={handleChange}
                                   placeholder="Email" className="border border-gray-300 p-2"/>
                            {error.email && <p className="text-red-500">{error.email}</p>}
                        </p>
                        <p className="mb-4">
                            <label className="block text-sm font-bold mb-2" htmlFor="phoneno">
                                Phone Number
                            </label>
                            <input
                                type="text"
                                name="phoneno"
                                id="phoneno"
                                value={formData.phoneno}
                                onChange={handleChange}
                                className="w-full px-3 py-2 border rounded-lg"
                                required
                            />
                            {error.phoneno && <p className="text-red-500 text-xs mt-1">{error.phoneno}</p>}
                        </p>
                        <p>
                            <label className="block text-sm font-bold mb-2" htmlFor="notickets">No. of Tickets:</label>
                            <select name="notickets" value={formData.notickets} onChange={handleChange}
                                    className="border border-gray-300 p-2">
                                <option value="">Select No. of Tickets</option>
                                {[...Array(maxTickets)].map((ticket, index) => {
                                    return <option key={index} value={index + 1}>{index + 1}</option>
                                })}
                            </select>
                            {error.notickets && <p className="text-red-500">{error.notickets}</p>}
                        </p>
                        <p>
                            <button onClick={handleSubmit}
                                    className="w-32 bg-blue-500 hover:bg-amber-500 text-white p-2 mt-2">Submit
                            </button>
                        </p>
                        <p className="text-red-600">
                            {message}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default FriendsAndFamily;