// src/contexts/AuthContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import {Link, NavLink, useLocation, useNavigate} from 'react-router-dom';
import { auth } from '../firebase-config';
import { signOut, createUserWithEmailAndPassword, signInWithEmailAndPassword,sendEmailVerification,onAuthStateChanged } from 'firebase/auth';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
            setLoading(false);
            //window.location.href = '/';
            console.log('Location from, Auth Context:',window.location.href)
            /*if (window.location.href.includes('login')) {
                window.location.href = '/';
            }*/
            const page_redirect = localStorage.getItem('page_redirect');
            console.log('Page Redirect from Auth Context:', page_redirect);
            if (page_redirect) {
                console.log('Page Redirect from Auth Context:', page_redirect);
                localStorage.removeItem('page_redirect');
                if (page_redirect === 'rys') {
                   navigate('/reserve-your-seat');
                } else {
                    //window.location.href = `/${page_redirect}`;
                    navigate(`/${page_redirect}`);
                };
            }
        });

        return () => unsubscribe();
    }, []);

    const logOut = () => {
        signOut(auth)
            .then(() => {
                navigate('/login')
            })
            .catch((error) => {
            console.error('Error signing out:', error);
        });
    };

    const signUp = (email, password) => {
        return createUserWithEmailAndPassword(auth, email, password);
    };

    const signIn = (email, password) => {
        return signInWithEmailAndPassword(auth,email, password);
    };

    const sendVerificationEmail = (user) => {
        return sendEmailVerification(user);
    };

    const value = {
        currentUser,
        setCurrentUser,
        auth,
        logOut,
        signUp,
        signIn,
        sendVerificationEmail
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};
