import React, { useState, useEffect} from 'react';
import HowMusicHealsBg from "../assets/img/meditaion-healing-background.jpg";
import AudioPlayer from "./AudioPlayer";
const HowMusicHeals = () => {
    const [audioSource, setAudioSource] = useState('');
    const [audioVisible, setAudioVisible] = useState(false);

    const audioChange = (e) => {
        const value = e.target.value;
        console.log('audio input changed', value);

        const audioMap = {
            Aquarius: '/assets/audio/BirdsZodiac/Aquarius.mp3',
            Aries: '/assets/audio/BirdsZodiac/Aries.mp3',
            Cancer: '/assets/audio/BirdsZodiac/Cancer.mp3',
            Capricorn: '/assets/audio/BirdsZodiac/Capricorn.mp3',
            Gemini: '/assets/audio/BirdsZodiac/Gemini.mp3',
            Leo: '/assets/audio/BirdsZodiac/Leo.mp3',
            Libra: '/assets/audio/BirdsZodiac/Libra.mp3',
            Pisces: '/assets/audio/BirdsZodiac/Pisces.mp3',
            Sagittarius: '/assets/audio/BirdsZodiac/Sagittarius.mp3',
            Scorpio: '/assets/audio/BirdsZodiac/Scorpio.mp3',
            Taurus: '/assets/audio/BirdsZodiac/Taurus.mp3',
            Virgo: '/assets/audio/BirdsZodiac/Virgo.mp3',
        };

        setAudioSource(audioMap[value] || '');
        setAudioVisible(true);
    };
    useEffect(() => {
        if (audioSource!==''){
            setAudioVisible(true);
        }

        console.log('audioSource updated', audioSource);
    }, [audioSource]);
    return (
        <>
            <div className="font-poppins max-w-full mb-20">
    <img className="w-full" src={HowMusicHealsBg} alt="first slide" />
        <h3 className="page-title text-center text-amber-600 font-bold text-4xl p-3">How Music Heals</h3>
        <div className="container body-alignment">
            <div className='row'>
                <div className="col-md-12 modal-a-tag">
                    <p className="float-right">Show Instructions</p>
                <br/><br/>
            </div>
        </div>

        <div className="row tabes-data">
            <div className="col-md-12">
                <h3 className="tabs-title text-2xl font-bold text-red-600">Listen Zodiac Music</h3>
                <p>Listen to amazing and soothing music(ragas) dedicated for each zodiac sign. Listening to music elevates
                    the
                    level of consciousness of the listeners, helping them to experience inner peace and harmony. This music is
                    outcome of more than 25 years of research by Dr. Sri Ganapathy Sachchidananda Swamiji.</p>
            </div>

        </div>
        <div className='row mb-6'>
            <div className='col-md-2'></div>
            <div className='col-md-8 music-calss'>
                <p className="text-xl font-bold text-amber-600">Listening to your healing music based on your zodiac sign.</p>
                <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"onChange={audioChange}>
                    <option value="">Select Zodiac Sign</option>
                    <option value="Aquarius">Aquarius</option>
                    <option value="Aries">Aries</option>
                    <option value="Cancer">Cancer</option>
                    <option value="Capricorn">Capricorn</option>
                    <option value="Gemini">Gemini</option>
                    <option value="Leo">Leo</option>
                    <option value="Libra">Libra</option>
                    <option value="Pisces">Pisces</option>
                    <option value="Sagittarius">Sagittarius</option>
                    <option value="Scorpio">Scorpio</option>
                    <option value="Taurus">Taurus</option>
                    <option value="Virgo">Virgo</option>
                </select>
                {audioVisible && <AudioPlayer src={audioSource}/>}
            </div>
            <div className='col-md-2'></div>

        </div>
    <div className="row view-sgs" >
        <div className="col-md-12">
            <h3 className="tabs-title text-2xl font-bold text-red-600">Nada (The Sound of Music)</h3>
            <p>
                A look back into the history of humanity shows us that human beings have always expressed emotions through
                music. Whether it is joy, anguish, or excitement, it is done effortlessly even without formal knowledge of music
                or its techniques. Music is so deeply ingrained within us that very few of us give any special thought as to
                where it originates. It is essentially created from feelings of happiness or sadness, which come from the mind.</p>

            <p>Music therapists have typically focused on Raga’s (melodious tune) vibrations and effects on the nervous system.
                Sri Swamiji takes it a step further by reaching out to the Nada Bindu (the center point) to stimulate and heal.
                This technique is called Nada Yoga Chikitsa, or healing through music. Ragas, or musical chords with vibrations,
                emerge out of this Nada like the waves of an ocean or Sagara—hence the name Raga Sagara. The word “Ra Ga Sa Ga
                Ra” is a palindrome that has been coined by Sri Swamiji to signify the yogic technique of Life Cycle.
            </p>

            <br/>
                <h3 className="tabs-title text-2xl font-bold text-red-600">Nada Chikitsa (Healing Through Music)</h3>
                <p>Nada Chikitsa is the literal translation of Sound Healing in the ancient Sanskrit language. There are historical
                records that prove that Nada Chikitsa has been used as an age-old tradition.</p>

                <p>Music is a force that unites humanity the world over. Musical Ragas are said to have therapeutical uses and help
                in the treatment of patients with afflictions of physical and mental nature. Nada Chikitsa aims at curing ailments
                through music.</p>

                <p>Swamiji has been conducting immense research in the fields of Spiritual Music Therapy and the spiritual use of
                Nāda for the welfare of humanity. His research involving the knowledge of music, astronomy, yoga, and anatomy led
                him to develop the unique concept of treating illnesses through music for meditation and healing.</p>

                <p>According to Sri Swamiji, “Nada vibrations work through the chord of symphony existing between man and his
                surroundings. The human body has 72,000 astral nerves (nadis) that incessantly vibrate in a specific rhythmic
                pattern. Disturbance in this rhythmic pattern is the root cause of disease. The musical notes restore their normal
                rhythmic pattern, thereby bringing about good health. Listen with your heart and not with intellect.”</p>

                <p>Sri Swamiji’s method involves the appropriate use of Raga scales for the five elements. The vibrations activate
                the chakra (one of many energy centers in the body) related to the organ. His music therapy research center has
                patients of varied disorders registered for therapy sessions. Sri Swamiji encourages both passive and active
                therapy and emphatically states that one need not be a musician to enjoy the therapeutic effect of music. Each
                raga has been associated with a therapeutic effect on the listener.</p>

                <p>His music is popular in several hospitals, schools and educational institutions in India and abroad, as well as in
                radio stations, orphanages and homes for the destitute and elderly. Many people with psychosomatic ailments or
                chronic conditions, terminally sick patients, jail inmates, and police personnel are using His music for stress
                reduction, finding immense benefit. However, Sri Swamiji emphasizes that music healing is an adjuvant and
                supportive method and not a substitute for medical care.</p>

                <p>Sri Swamiji is a great composer of the present times; He composed more than 5,000 different songs in several
                languages and He has produced more than 200 albums.</p>

                <p>A globetrotter, Sri Swamiji has performed in some of the most prestigious concert halls worldwide, such as the
                Sydney Opera House and Royal Albert Hall, attracting audiences of thousands. Sri Swamiji massages the human
                nervous system with rhythms and melodies to soothe, cleanse, balance, and heal. He brings about a positive change
                in the physical, mental, and spiritual lives of His audiences. The positive spiritual vibrations that emanate from
                His personality and music spread for miles around, causing an enormous change in the environment. A certain
                    ambiance of peace, tranquility, and serenity pervade wherever Sri Swamiji travels.</p>

                <br/><br/>
                    <h3 className="tabs-title text-2xl font-bold text-red-600">Other Monumental Works of Sri Swamiji related to Music</h3>
                    <ol>
                        <li><strong className="text-blue-600 font-bold"> Ragini Music Therapy & Research Centre– </strong>At this holistic hospital, music therapy is equipped with a state-of-the-art acoustic system and headed by experts in music and musicology and doctors working under the direct guidance and supervision of Pujya Swamiji.</li>
                        <li><strong className="text-blue-600 font-bold"> Ragaragini Nada Yoga–</strong>A distinguished book in Telugu and English on music and its therapeutic aspects authored by Sri Swamiji. Raga Ragini Nada Yoga is a magnificent technical treatise spanning research in music, medicine, and spiritual science.</li>
                        <li><strong className="text-blue-600 font-bold"> Naada Mandapa: –  </strong> The magnificent auditorium constructed with a unique design, and the world’s first and only Sapta Swara Devata Mandapa built with musical stone pillars.</li>
                        <li><strong className="text-blue-600 font-bold"> Raga Ragini Sangeeta Vidyalaya–  </strong>teaches music and bhajans to interested youngsters free of cost.</li>
                        <li><strong className="text-blue-600 font-bold"> Awards–  </strong>The services of scholars in various fields of specialization like Veda, Nada, Sastra, Yoga etc., are recognized at the international level. Music concerts by artists of international repute are a notable feature of Sri Swamiji’s programs in Mysore and elsewhere.</li>
                        <li><strong className="text-blue-600 font-bold"> Research for Doctoral Degree (PhD)–  </strong>Several research projects are being conducted by scholars working on a doctoral thesis on the mission of Sri Swamiji’s Music.</li>
                        <li><strong className="text-blue-600 font-bold"> Media Releases–  </strong>Publications pertaining to music and other subjects are regularly brought out at the press, specially installed for this purpose at the Mysore Ashram. Hundreds of DVDs and CDs of Sri Swamiji’s concerts and programs, along with numerous digital albums on sites such as iTunes are also widely available.</li>
                        <li><strong className="text-blue-600 font-bold"> Yoga Sangeeta–  </strong>Yoga Sangeeta is a non-profit organization founded by the His Holiness Sri Ganapathy Sachchidananda Swamiji. The primary mission of Yoga Sangeeta is to promote healing by music. Since its inception in early 2008, Yoga Sangeeta has organized many concerts and programs of Sri Swamiji and other artists from around the world, many of which have been webcast live as a service to the public.</li>
                    </ol>

        </div>

    </div>


</div>
            </div>
</>
    );
};

export default HowMusicHeals;
