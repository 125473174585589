// src/components/AudioPlayer.js
import React, { useRef, useState, useEffect } from 'react';
import './AudioPlayer.css';

const AudioPlayer = ({src}) => {
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.load();
            setIsPlaying(false);
        }
    }, [src]);

    const togglePlayPause = () => {
        if (!audioRef.current) return;

        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play().catch(error => {
                console.error('Error playing audio:', error);
            });
        }
        setIsPlaying(!isPlaying);
    };

    const stopAudio = () => {
        if (!audioRef.current) return;

        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        setIsPlaying(false);
    };

    return (
        <div className="audio-player">
            {/*<audio ref={audioRef} >
                <source src={src} type="audio/mpeg" />
                Your browser does not support the audio element.
            </audio>*/}
            <audio controls src={src} id="player" type="audio/mpeg">
                Your browser does not support the audio element.
            </audio>
            {/*<button onClick={togglePlayPause}>
                {isPlaying ? 'Pause' : 'Play'}
            </button>
            <button onClick={stopAudio}>Stop</button>*/}
        </div>
    );
};

export default AudioPlayer;
